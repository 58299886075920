import React from 'react'
import CustomizedMenuOptions from '../../Custom/CustomizedMenuOptions';
import { useTranslation } from 'react-i18next';
import { useStateContext } from '../../../contexts/auth-context';
import { GraphQLClient } from 'graphql-request';
import { createGraphQLClientWithMiddleware } from '../../../services/graphqlClient';
import { useUserQuery } from '../../../generated/company-user';
import { useUserInformationQuery } from '../../../generated/user-infomation';
import { IMenuOption } from '../../../types/global';
import { SettingsOutlined } from '@mui/icons-material';

type Props = {
    tableRef: React.MutableRefObject<{
        setMode: (mode: 'everyone' | 'onlyme') => void
    } | undefined>
}

const ConfigurableAgGridEditButton = ({
    tableRef
}: Props) => {
    const { t } = useTranslation();
    const {
        state: { authUser },
    } = useStateContext();
    const graphQLClientWithHeaderCompany: GraphQLClient =
        createGraphQLClientWithMiddleware("company-user");

    const graphQLClientWithHeaderSetting: GraphQLClient =
        createGraphQLClientWithMiddleware("general");
    const { data, isSuccess, isLoading } = useUserQuery(
        graphQLClientWithHeaderCompany,
        {
            uniqueInput: { unique_id: authUser?.unique_id },
        },
        { enabled: !!authUser?.unique_id }
    );

    const { data: userInfoData, isSuccess: isSuccessUserInfo } =
        useUserInformationQuery(
            graphQLClientWithHeaderSetting,
            {
                uniqueInput: { user_unique_id: authUser?.unique_id },
            },
            { enabled: !!authUser?.unique_id }
        );
    console.log(authUser, data, userInfoData)
    const isAdmin = !!userInfoData?.userinformation?.role_list?.find((role) => role.name === 'SuperAdmin')
    let options:IMenuOption[] = []
    if (isAdmin) {
        options = [
            {
                icon: <SettingsOutlined />,
                value: "จัดรูปแบบตาราง (ทุกผู้ใช้งาน)",
            },
            {
                icon: <SettingsOutlined />,
                value: "จัดรูปแบบตาราง (เฉพาะตนเอง)",
            },
        ]
    } else {
        options = [
            {
                icon: <SettingsOutlined />,
                value: "จัดรูปแบบตาราง (เฉพาะตนเอง)",
            },
        ]
    }
    return (
        <CustomizedMenuOptions
            size="medium"
            label={t("button.option")}
            isIcon
            options={options}
            onSelect={(e) => {
                const value = e.target as HTMLElement;
                switch (value.innerText) {
                    case `จัดรูปแบบตาราง (ทุกผู้ใช้งาน)`:
                        // setDisabled(false);
                        tableRef.current?.setMode('everyone')
                        break;
                    default:
                        tableRef.current?.setMode('onlyme')
                }
            }}
        // disabled={!disabled}
        />
    )
}

export default ConfigurableAgGridEditButton
import { GraphQLClient } from 'graphql-request';
import { RequestInit } from 'graphql-request/dist/types.dom';
import { useMutation, useQuery, UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Any: any;
  AnyAggridInput: any;
  DateTime: any;
  Decimal: any;
  JSON: any;
};

export type AccessPermission = {
  __typename?: 'AccessPermission';
  all?: Maybe<All_AccessPermission>;
  bom?: Maybe<Manufacture_Bom_AccessPermission>;
  contact?: Maybe<Contact_AccessPermission>;
  contact_report?: Maybe<Contact_Report_AccessPermission>;
  customer?: Maybe<Customer_AccessPermission>;
  delivery?: Maybe<Delivery_AccessPermission>;
  delivery_order?: Maybe<Delivery_Order_AccessPermission>;
  delivery_trip?: Maybe<Delivery_Trip_AccessPermission>;
  event?: Maybe<Event_AccessPermission>;
  goods_adjustment?: Maybe<Goods_Adjustment_AccessPermission>;
  goods_issue?: Maybe<Goods_Issue_AccessPermission>;
  goods_receive?: Maybe<Goods_Receive_AccessPermission>;
  goods_return?: Maybe<Goods_Return_AccessPermission>;
  goods_transfer?: Maybe<Goods_Transfer_AccessPermission>;
  inventory?: Maybe<Inventory_AccessPermission>;
  inventory_current_stock_by_lot_report?: Maybe<Inventory_Current_Stock_By_Lot_Report_AccessPermission>;
  inventory_current_stock_report?: Maybe<Inventory_Current_Stock_Report_AccessPermission>;
  inventory_movement_summary_report?: Maybe<Inventory_Movement_Summary_Report_AccessPermission>;
  inventory_report?: Maybe<Inventory_Report_AccessPermission>;
  inventory_trace_entry_report?: Maybe<Inventory_Trace_Entry_Report_AccessPermission>;
  item?: Maybe<Item_AccessPermission>;
  logistic_report?: Maybe<Logistic_Report_AccessPermission>;
  manufacture?: Maybe<Manufacture_AccessPermission>;
  manufacture_order?: Maybe<Manufacture_Manufacture_Order_AccessPermission>;
  manufacture_report?: Maybe<Manufacture_Manufacture_Report_AccessPermission>;
  project?: Maybe<Project_AccessPermission>;
  projects?: Maybe<Projects_AccessPermission>;
  purchase?: Maybe<Purchase_AccessPermission>;
  purchase_order?: Maybe<Purchase_Order_AccessPermission>;
  purchase_report?: Maybe<Purchase_Report_AccessPermission>;
  purchase_request?: Maybe<Purchase_Request_AccessPermission>;
  purchase_return?: Maybe<Purchase_Return_AccessPermission>;
  quotation?: Maybe<Quotation_AccessPermission>;
  role?: Maybe<Role_AccessPermission>;
  routing?: Maybe<Manufacture_Routing_AccessPermission>;
  sales?: Maybe<Sales_AccessPermission>;
  sales_order?: Maybe<Sales_Order_AccessPermission>;
  sales_report?: Maybe<Sales_Report_AccessPermission>;
  sales_return?: Maybe<Sales_Return_AccessPermission>;
  setting?: Maybe<Setting_AccessPermission>;
  setting_approval?: Maybe<Setting_Approval_AccessPermission>;
  setting_company?: Maybe<Setting_Company_AccessPermission>;
  setting_inventory?: Maybe<Setting_Inventory_AccessPermission>;
  setting_logistic?: Maybe<Setting_Logistic_AccessPermission>;
  setting_manufacture?: Maybe<Setting_Manufacture_AccessPermission>;
  setting_project?: Maybe<Setting_Project_AccessPermission>;
  setting_remark?: Maybe<Setting_Remark_AccessPermission>;
  user_information?: Maybe<User_Information_AccessPermission>;
  vendor?: Maybe<Vendor_AccessPermission>;
  work_order?: Maybe<Manufacture_Work_Order_AccessPermission>;
};

export type AccessPermissionInput = {
  all?: InputMaybe<All_AccessPermissionInput>;
  bom?: InputMaybe<Manufacture_Bom_AccessPermissionInput>;
  contact?: InputMaybe<Contact_AccessPermissionInput>;
  contact_report?: InputMaybe<Contact_Report_AccessPermissionInput>;
  customer?: InputMaybe<Customer_AccessPermissionInput>;
  delivery?: InputMaybe<Delivery_AccessPermissionInput>;
  delivery_order?: InputMaybe<Delivery_Order_AccessPermissionInput>;
  delivery_trip?: InputMaybe<Delivery_Trip_AccessPermissionInput>;
  event?: InputMaybe<Event_AccessPermissionInput>;
  goods_adjustment?: InputMaybe<Goods_Adjustment_AccessPermissionInput>;
  goods_issue?: InputMaybe<Goods_Issue_AccessPermissionInput>;
  goods_receive?: InputMaybe<Goods_Receive_AccessPermissionInput>;
  goods_return?: InputMaybe<Goods_Return_AccessPermissionInput>;
  goods_transfer?: InputMaybe<Goods_Transfer_AccessPermissionInput>;
  inventory?: InputMaybe<Inventory_AccessPermissionInput>;
  inventory_current_stock_by_lot_report?: InputMaybe<Inventory_Current_Stock_By_Lot_Report_AccessPermissionInput>;
  inventory_current_stock_report?: InputMaybe<Inventory_Current_Stock_Report_AccessPermissionInput>;
  inventory_movement_summary_report?: InputMaybe<Inventory_Movement_Summary_Report_AccessPermissionInput>;
  inventory_report?: InputMaybe<Inventory_Report_AccessPermissionInput>;
  inventory_trace_entry_report?: InputMaybe<Inventory_Trace_Entry_Report_AccessPermissionInput>;
  item?: InputMaybe<Item_AccessPermissionInput>;
  logistic_report?: InputMaybe<Logistic_Report_AccessPermissionInput>;
  manufacture?: InputMaybe<Manufacture_AccessPermissionInput>;
  manufacture_order?: InputMaybe<Manufacture_Manufacture_Order_AccessPermissionInput>;
  manufacture_report?: InputMaybe<Manufacture_Manufacture_Report_AccessPermissionInput>;
  project?: InputMaybe<Project_AccessPermissionInput>;
  projects?: InputMaybe<Projects_AccessPermissionInput>;
  purchase?: InputMaybe<Purchase_AccessPermissionInput>;
  purchase_order?: InputMaybe<Purchase_Order_AccessPermissionInput>;
  purchase_report?: InputMaybe<Purchase_Report_AccessPermissionInput>;
  purchase_request?: InputMaybe<Purchase_Request_AccessPermissionInput>;
  purchase_return?: InputMaybe<Purchase_Return_AccessPermissionInput>;
  quotation?: InputMaybe<Quotation_AccessPermissionInput>;
  role?: InputMaybe<Role_AccessPermissionInput>;
  routing?: InputMaybe<Manufacture_Routing_AccessPermissionInput>;
  sales?: InputMaybe<Sales_AccessPermissionInput>;
  sales_order?: InputMaybe<Sales_Order_AccessPermissionInput>;
  sales_report?: InputMaybe<Sales_Report_AccessPermissionInput>;
  sales_return?: InputMaybe<Sales_Return_AccessPermissionInput>;
  setting?: InputMaybe<Setting_AccessPermissionInput>;
  setting_approval?: InputMaybe<Setting_Approval_AccessPermissionInput>;
  setting_company?: InputMaybe<Setting_Company_AccessPermissionInput>;
  setting_inventory?: InputMaybe<Setting_Inventory_AccessPermissionInput>;
  setting_logistic?: InputMaybe<Setting_Logistic_AccessPermissionInput>;
  setting_manufacture?: InputMaybe<Setting_Manufacture_AccessPermissionInput>;
  setting_project?: InputMaybe<Setting_Project_AccessPermissionInput>;
  setting_remark?: InputMaybe<Setting_Remark_AccessPermissionInput>;
  user_information?: InputMaybe<User_Information_AccessPermissionInput>;
  vendor?: InputMaybe<Vendor_AccessPermissionInput>;
  work_order?: InputMaybe<Manufacture_Work_Order_AccessPermissionInput>;
};

export type ActivityLogCopyObject = {
  __typename?: 'ActivityLogCopyObject';
  id?: Maybe<Scalars['Int']>;
  unique_id?: Maybe<Scalars['String']>;
};

export type ActivityLogCopyObjectInput = {
  id?: InputMaybe<Scalars['Int']>;
  unique_id?: InputMaybe<Scalars['String']>;
};

export type ActivityLogDetailObject = {
  __typename?: 'ActivityLogDetailObject';
  added_related_employee?: Maybe<ActivityLogRelatedEmployeeObject>;
  attachment_list?: Maybe<Array<Scalars['String']>>;
  copied_from?: Maybe<ActivityLogCopyObject>;
  copied_to?: Maybe<ActivityLogCopyObject>;
  curr_status?: Maybe<Status>;
  deleted_related_employee?: Maybe<ActivityLogRelatedEmployeeObject>;
  message?: Maybe<Array<Scalars['String']>>;
  prev_status?: Maybe<Status>;
  updated_fields?: Maybe<Scalars['JSON']>;
};

export type ActivityLogDetailObjectInput = {
  added_related_employee?: InputMaybe<ActivityLogRelatedEmployeeObjectInput>;
  attachment_list?: InputMaybe<Array<Scalars['String']>>;
  copied_from?: InputMaybe<ActivityLogCopyObjectInput>;
  copied_to?: InputMaybe<ActivityLogCopyObjectInput>;
  curr_status?: InputMaybe<Status>;
  deleted_related_employee?: InputMaybe<ActivityLogRelatedEmployeeObjectInput>;
  message?: InputMaybe<Array<Scalars['String']>>;
  prev_status?: InputMaybe<Status>;
  secondary_operation?: InputMaybe<ActivityType>;
  updated_fields?: InputMaybe<Scalars['JSON']>;
};

export type ActivityLogRelatedEmployeeObject = {
  __typename?: 'ActivityLogRelatedEmployeeObject';
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  user_unique_id?: Maybe<Scalars['String']>;
};

export type ActivityLogRelatedEmployeeObjectInput = {
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  user_unique_id?: InputMaybe<Scalars['String']>;
};

export type ActivityLogUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export enum ActivityType {
  Active = 'active',
  AddRelatedEmployee = 'add_related_employee',
  Approve = 'approve',
  Cancel = 'cancel',
  ChangePassword = 'change_password',
  Comment = 'comment',
  Copy = 'copy',
  Create = 'create',
  CreateImporter = 'create_importer',
  Delete = 'delete',
  DeleteRelatedEmployee = 'delete_related_employee',
  Document = 'document',
  Edit = 'edit',
  EditImporter = 'edit_importer',
  EditPermission = 'edit_permission',
  FactChange = 'fact_change',
  Image = 'image',
  InActive = 'in_active',
  MainBom = 'main_bom',
  MainRouting = 'main_routing',
  StatusChange = 'status_change'
}

export enum AggridArrayFilterType {
  Array = 'array'
}

export type AggridBooleanFilterModelInput = {
  filter?: InputMaybe<Scalars['String']>;
  filterType?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<AggridBooleanFilterModelType>;
};

export enum AggridBooleanFilterModelType {
  Equals = 'equals',
  NotEqual = 'notEqual'
}

export type AggridDateFilterModelInput = {
  filter?: InputMaybe<Scalars['String']>;
  filterTo?: InputMaybe<Scalars['String']>;
  filterType?: InputMaybe<AggridDateFilterType>;
  type?: InputMaybe<AggridDateFilterModelType>;
};

export enum AggridDateFilterModelType {
  Blank = 'blank',
  Empty = 'empty',
  Equals = 'equals',
  GreaterThan = 'greaterThan',
  GreaterThanOrEqual = 'greaterThanOrEqual',
  InRange = 'inRange',
  LessThan = 'lessThan',
  LessThanOrEqual = 'lessThanOrEqual',
  NotBlank = 'notBlank',
  NotEqual = 'notEqual'
}

export enum AggridDateFilterType {
  Date = 'date'
}

export type AggridFloatFilterModelInput = {
  condition1?: InputMaybe<AggridFloatFilterModelInputCondition>;
  condition2?: InputMaybe<AggridFloatFilterModelInputCondition>;
  filter?: InputMaybe<Scalars['Float']>;
  filterTo?: InputMaybe<Scalars['Float']>;
  filterType?: InputMaybe<AggridNumberFilterType>;
  operator?: InputMaybe<AggridOperatorFilter>;
  type?: InputMaybe<AggridNumberFilterModelType>;
};

export type AggridFloatFilterModelInputCondition = {
  filter?: InputMaybe<Scalars['Float']>;
  filterTo?: InputMaybe<Scalars['Float']>;
  filterType?: InputMaybe<AggridNumberFilterType>;
  type?: InputMaybe<AggridNumberFilterModelType>;
};

export type AggridGenericArrayFilterModelInput = {
  filterType?: InputMaybe<AggridArrayFilterType>;
  type?: InputMaybe<AggridGenericArrayFilterModelType>;
  values?: InputMaybe<Scalars['Any']>;
};

export enum AggridGenericArrayFilterModelType {
  Equals = 'equals',
  Has = 'has',
  HasEvery = 'hasEvery',
  HasSome = 'hasSome',
  IsEmpty = 'isEmpty'
}

export enum AggridISimpleFilterModelType {
  Blank = 'blank',
  Contains = 'contains',
  Empty = 'empty',
  EndsWith = 'endsWith',
  Equals = 'equals',
  GreaterThan = 'greaterThan',
  GreaterThanOrEqual = 'greaterThanOrEqual',
  InRange = 'inRange',
  LessThan = 'lessThan',
  LessThanOrEqual = 'lessThanOrEqual',
  NotBlank = 'notBlank',
  NotContains = 'notContains',
  NotEqual = 'notEqual',
  StartsWith = 'startsWith'
}

export type AggridIntFilterModelInput = {
  condition1?: InputMaybe<AggridIntFilterModelInputCondition>;
  condition2?: InputMaybe<AggridIntFilterModelInputCondition>;
  filter?: InputMaybe<Scalars['Int']>;
  filterTo?: InputMaybe<Scalars['Int']>;
  filterType?: InputMaybe<AggridNumberFilterType>;
  operator?: InputMaybe<AggridOperatorFilter>;
  type?: InputMaybe<AggridNumberFilterModelType>;
};

export type AggridIntFilterModelInputCondition = {
  filter?: InputMaybe<Scalars['Int']>;
  filterTo?: InputMaybe<Scalars['Int']>;
  filterType?: InputMaybe<AggridNumberFilterType>;
  type?: InputMaybe<AggridNumberFilterModelType>;
};

export enum AggridNumberFilterModelType {
  Blank = 'blank',
  Empty = 'empty',
  Equals = 'equals',
  GreaterThan = 'greaterThan',
  GreaterThanOrEqual = 'greaterThanOrEqual',
  InRange = 'inRange',
  LessThan = 'lessThan',
  LessThanOrEqual = 'lessThanOrEqual',
  NotBlank = 'notBlank',
  NotEqual = 'notEqual'
}

export enum AggridNumberFilterType {
  Number = 'number'
}

export enum AggridObjectArrayFilterModelType {
  Every = 'every',
  None = 'none',
  Some = 'some'
}

export enum AggridObjectArrayFilterType {
  ObjectArray = 'objectArray'
}

export enum AggridOperatorFilter {
  And = 'AND',
  Or = 'OR'
}

export type AggridSetSingleFilterModelInput = {
  condition1?: InputMaybe<AggridSetSingleFilterModelInputCondition>;
  condition2?: InputMaybe<AggridSetSingleFilterModelInputCondition>;
  filterType?: InputMaybe<Scalars['String']>;
  operator?: InputMaybe<AggridOperatorFilter>;
  values?: InputMaybe<Array<InputMaybe<Scalars['Any']>>>;
};

export type AggridSetSingleFilterModelInputCondition = {
  filterType?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<AggridSetSingleFilterModelType>;
  values?: InputMaybe<Array<InputMaybe<Scalars['Any']>>>;
};

export enum AggridSetSingleFilterModelType {
  Blank = 'blank',
  Contains = 'contains',
  Equals = 'equals',
  NotBlank = 'notBlank',
  NotContains = 'notContains',
  NotEqual = 'notEqual'
}

export type AggridSortModelItem = {
  colId?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<SortOrder>;
};

export type AggridSortModelItemInput = {
  colId: Scalars['String'];
  sort: SortOrder;
};

export type AggridStringFilterModelInput = {
  condition1?: InputMaybe<AggridStringFilterModelInputCondition>;
  condition2?: InputMaybe<AggridStringFilterModelInputCondition>;
  filter?: InputMaybe<Scalars['String']>;
  filterTo?: InputMaybe<Scalars['String']>;
  filterType?: InputMaybe<AggridTextFilterType>;
  mode?: InputMaybe<AggridStringModeOption>;
  operator?: InputMaybe<AggridOperatorFilter>;
  type?: InputMaybe<AggridStringFilterModelType>;
};

export type AggridStringFilterModelInputCondition = {
  filter?: InputMaybe<Scalars['String']>;
  filterTo?: InputMaybe<Scalars['String']>;
  filterType?: InputMaybe<AggridTextFilterType>;
  mode?: InputMaybe<AggridStringModeOption>;
  type?: InputMaybe<AggridStringFilterModelType>;
};

export enum AggridStringFilterModelType {
  Blank = 'blank',
  Contains = 'contains',
  Empty = 'empty',
  EndsWith = 'endsWith',
  Equals = 'equals',
  NotBlank = 'notBlank',
  NotContains = 'notContains',
  NotEqual = 'notEqual',
  StartsWith = 'startsWith'
}

export enum AggridStringModeOption {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type AggridTemplateInput = {
  endRow?: InputMaybe<Scalars['Int']>;
  filterModel?: InputMaybe<Scalars['Any']>;
  sortModel?: InputMaybe<Array<AggridSortModelItemInput>>;
  startRow?: InputMaybe<Scalars['Int']>;
};

export enum AggridTextFilterType {
  Text = 'text'
}

export type All_AccessPermission = {
  __typename?: 'All_AccessPermission';
  all: PermissionBinaryType;
};

export type All_AccessPermissionInput = {
  all: PermissionBinaryType;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  isSet?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BooleanFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBooleanFilter>;
};

export type BooleanNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  isSet?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBooleanFilter>;
};

export type BooleanNullableListFilter = {
  equals?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  has?: InputMaybe<Scalars['Boolean']>;
  hasEvery?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  hasSome?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  isEmpty?: InputMaybe<Scalars['Boolean']>;
};

export type Company = {
  __typename?: 'Company';
  address_list?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  company_permission?: Maybe<CompanyPermission>;
  contact_channel_list?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  expired_date?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  identity_no?: Maybe<Scalars['String']>;
  img_url?: Maybe<Array<Scalars['String']>>;
  is_active: Scalars['Int'];
  is_registered_vat?: Maybe<Scalars['Boolean']>;
  last_active_date?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  owner_email: Scalars['String'];
  register_date?: Maybe<Scalars['DateTime']>;
  resubscription_date?: Maybe<Scalars['DateTime']>;
  status_remark?: Maybe<Scalars['String']>;
  subscription_level_id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  unique_id: Scalars['String'];
  user_email_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  vat_registration_date?: Maybe<Scalars['DateTime']>;
};

export type CompanyPermission = {
  __typename?: 'CompanyPermission';
  company?: Maybe<Company>;
  company_id: Scalars['String'];
  id: Scalars['Int'];
};

export type Contact_AccessPermission = {
  __typename?: 'Contact_AccessPermission';
  view: PermissionBinaryType;
};

export type Contact_AccessPermissionInput = {
  view: PermissionBinaryType;
};

export type Contact_Report_AccessPermission = {
  __typename?: 'Contact_report_AccessPermission';
  view: PermissionBinaryViewType;
};

export type Contact_Report_AccessPermissionInput = {
  view: PermissionBinaryViewType;
};

export type Creatable = {
  __typename?: 'Creatable';
  name: Scalars['String'];
  usage_field_name: Scalars['String'];
  usage_field_type: EntityTypeEnum;
};

export type CreatableCreateInput = {
  name: Scalars['String'];
  usage_field_name: Scalars['String'];
  usage_field_type: EntityTypeEnum;
};

export type CreatableFilterInput = {
  name?: InputMaybe<Scalars['String']>;
  usage_field_name?: InputMaybe<Scalars['String']>;
  usage_field_type?: InputMaybe<EntityTypeEnum>;
};

export type CreatableUniqueCompoundInput = {
  name: Scalars['String'];
  usage_field_name: Scalars['String'];
  usage_field_type: EntityTypeEnum;
};

export type CreatableUniqueInput = {
  name_usage_field_type_usage_field_name: CreatableUniqueCompoundInput;
};

export type CreatorObject = {
  __typename?: 'CreatorObject';
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  img_url?: Maybe<Array<Scalars['String']>>;
  last_name?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
  user_unique_id?: Maybe<Scalars['String']>;
};

export type CustomTableView = {
  __typename?: 'CustomTableView';
  config: Scalars['JSON'];
  id: Scalars['Int'];
  name: Scalars['String'];
  user?: Maybe<User>;
  user_unique_id?: Maybe<Scalars['String']>;
};

export type CustomTableViewDeleteInput = {
  name: Scalars['String'];
  user_unique_id?: InputMaybe<Scalars['String']>;
};

export type CustomTableViewDeleteResult = {
  __typename?: 'CustomTableViewDeleteResult';
  count?: Maybe<Scalars['Int']>;
};

export type CustomTableViewSaveInput = {
  config: Scalars['JSON'];
  name: Scalars['String'];
  user_unique_id?: InputMaybe<Scalars['String']>;
};

export type CustomTableViewSearchInput = {
  name: Scalars['String'];
  user_unique_id?: InputMaybe<Scalars['String']>;
};

export type Customer_AccessPermission = {
  __typename?: 'Customer_AccessPermission';
  view: PermissionViewType;
};

export type Customer_AccessPermissionInput = {
  view: PermissionViewType;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type Delivery_AccessPermission = {
  __typename?: 'Delivery_AccessPermission';
  view: PermissionBinaryType;
};

export type Delivery_AccessPermissionInput = {
  view: PermissionBinaryType;
};

export type Delivery_Order_AccessPermission = {
  __typename?: 'Delivery_order_AccessPermission';
  view: PermissionViewType;
};

export type Delivery_Order_AccessPermissionInput = {
  view: PermissionViewType;
};

export type Delivery_Trip_AccessPermission = {
  __typename?: 'Delivery_trip_AccessPermission';
  view: PermissionViewType;
};

export type Delivery_Trip_AccessPermissionInput = {
  view: PermissionViewType;
};

export enum EntityTypeEnum {
  Batch = 'batch',
  Bom = 'bom',
  Contact = 'contact',
  CreditNote = 'credit_note',
  DeliveryOrder = 'delivery_order',
  DeliveryTrip = 'delivery_trip',
  DepositInvoice = 'deposit_invoice',
  Employee = 'employee',
  Event = 'event',
  GoodsAdjust = 'goods_adjust',
  GoodsIssue = 'goods_issue',
  GoodsReceive = 'goods_receive',
  GoodsTransfer = 'goods_transfer',
  Ingredient = 'ingredient',
  Item = 'item',
  ManufactureOrder = 'manufacture_order',
  PaymentMade = 'payment_made',
  PaymentReceive = 'payment_receive',
  Project = 'project',
  PurchaseInvoice = 'purchase_invoice',
  PurchaseOrder = 'purchase_order',
  PurchaseRequest = 'purchase_request',
  PurchaseReturn = 'purchase_return',
  Quotation = 'quotation',
  Role = 'role',
  Routing = 'routing',
  SalesInvoice = 'sales_invoice',
  SalesOrder = 'sales_order',
  SalesReturn = 'sales_return',
  Serial = 'serial',
  Task = 'task',
  Vehicle = 'vehicle',
  WorkCenter = 'work_center',
  WorkOrder = 'work_order'
}

export type Event_AccessPermission = {
  __typename?: 'Event_AccessPermission';
  view: PermissionViewType;
};

export type Event_AccessPermissionInput = {
  view: PermissionViewType;
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
};

export type FloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  isSet?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
};

export type FloatNullableListFilter = {
  equals?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  has?: InputMaybe<Scalars['Float']>;
  hasEvery?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  hasSome?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  isEmpty?: InputMaybe<Scalars['Boolean']>;
};

export type Goods_Adjustment_AccessPermission = {
  __typename?: 'Goods_adjustment_AccessPermission';
  view: PermissionViewType;
};

export type Goods_Adjustment_AccessPermissionInput = {
  view: PermissionViewType;
};

export type Goods_Issue_AccessPermission = {
  __typename?: 'Goods_issue_AccessPermission';
  view: PermissionViewType;
};

export type Goods_Issue_AccessPermissionInput = {
  view: PermissionViewType;
};

export type Goods_Receive_AccessPermission = {
  __typename?: 'Goods_receive_AccessPermission';
  view: PermissionViewType;
};

export type Goods_Receive_AccessPermissionInput = {
  view: PermissionViewType;
};

export type Goods_Return_AccessPermission = {
  __typename?: 'Goods_return_AccessPermission';
  view: PermissionViewType;
};

export type Goods_Return_AccessPermissionInput = {
  view: PermissionViewType;
};

export type Goods_Transfer_AccessPermission = {
  __typename?: 'Goods_transfer_AccessPermission';
  view: PermissionViewType;
};

export type Goods_Transfer_AccessPermissionInput = {
  view: PermissionViewType;
};

export type IntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']>;
  divide?: InputMaybe<Scalars['Int']>;
  increment?: InputMaybe<Scalars['Int']>;
  multiply?: InputMaybe<Scalars['Int']>;
  set?: InputMaybe<Scalars['Int']>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  isSet?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type IntNullableListFilter = {
  equals?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  has?: InputMaybe<Scalars['Int']>;
  hasEvery?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  hasSome?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  isEmpty?: InputMaybe<Scalars['Boolean']>;
};

export type Inventory_AccessPermission = {
  __typename?: 'Inventory_AccessPermission';
  view: PermissionBinaryType;
};

export type Inventory_AccessPermissionInput = {
  view: PermissionBinaryType;
};

export type Inventory_Current_Stock_By_Lot_Report_AccessPermission = {
  __typename?: 'Inventory_current_stock_by_lot_report_AccessPermission';
  view: PermissionBinaryViewType;
};

export type Inventory_Current_Stock_By_Lot_Report_AccessPermissionInput = {
  view: PermissionBinaryViewType;
};

export type Inventory_Current_Stock_Report_AccessPermission = {
  __typename?: 'Inventory_current_stock_report_AccessPermission';
  view: PermissionBinaryViewType;
};

export type Inventory_Current_Stock_Report_AccessPermissionInput = {
  view: PermissionBinaryViewType;
};

export type Inventory_Movement_Summary_Report_AccessPermission = {
  __typename?: 'Inventory_movement_summary_report_AccessPermission';
  view: PermissionBinaryViewType;
};

export type Inventory_Movement_Summary_Report_AccessPermissionInput = {
  view: PermissionBinaryViewType;
};

export type Inventory_Report_AccessPermission = {
  __typename?: 'Inventory_report_AccessPermission';
  view: PermissionBinaryViewType;
};

export type Inventory_Report_AccessPermissionInput = {
  view: PermissionBinaryViewType;
};

export type Inventory_Trace_Entry_Report_AccessPermission = {
  __typename?: 'Inventory_trace_entry_report_AccessPermission';
  view: PermissionBinaryViewType;
};

export type Inventory_Trace_Entry_Report_AccessPermissionInput = {
  view: PermissionBinaryViewType;
};

export type Item_AccessPermission = {
  __typename?: 'Item_AccessPermission';
  view: PermissionViewType;
};

export type Item_AccessPermissionInput = {
  view: PermissionViewType;
};

export type KeyValueSetting = {
  __typename?: 'KeyValueSetting';
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  key: Scalars['String'];
  value: Scalars['String'];
};

export type Logistic_Report_AccessPermission = {
  __typename?: 'Logistic_report_AccessPermission';
  view: PermissionBinaryViewType;
};

export type Logistic_Report_AccessPermissionInput = {
  view: PermissionBinaryViewType;
};

export type Manufacture_AccessPermission = {
  __typename?: 'Manufacture_AccessPermission';
  view: PermissionBinaryType;
};

export type Manufacture_AccessPermissionInput = {
  view: PermissionBinaryType;
};

export type Manufacture_Bom_AccessPermission = {
  __typename?: 'Manufacture_bom_AccessPermission';
  view: PermissionViewType;
};

export type Manufacture_Bom_AccessPermissionInput = {
  view: PermissionViewType;
};

export type Manufacture_Manufacture_Order_AccessPermission = {
  __typename?: 'Manufacture_manufacture_order_AccessPermission';
  view: PermissionViewType;
};

export type Manufacture_Manufacture_Order_AccessPermissionInput = {
  view: PermissionViewType;
};

export type Manufacture_Manufacture_Report_AccessPermission = {
  __typename?: 'Manufacture_manufacture_report_AccessPermission';
  view: PermissionBinaryViewType;
};

export type Manufacture_Manufacture_Report_AccessPermissionInput = {
  view: PermissionBinaryViewType;
};

export type Manufacture_Routing_AccessPermission = {
  __typename?: 'Manufacture_routing_AccessPermission';
  view: PermissionViewType;
};

export type Manufacture_Routing_AccessPermissionInput = {
  view: PermissionViewType;
};

export type Manufacture_Work_Order_AccessPermission = {
  __typename?: 'Manufacture_work_order_AccessPermission';
  view: PermissionViewType;
};

export type Manufacture_Work_Order_AccessPermissionInput = {
  view: PermissionViewType;
};

export type Mutation = {
  __typename?: 'Mutation';
  CustomTableViewDelete?: Maybe<CustomTableViewDeleteResult>;
  CustomTableViewSave?: Maybe<CustomTableView>;
  NotificationSettingCreate: NotificationSetting;
  NotificationSettingUpdate: NotificationSetting;
  NotificationUpdateMineApiToken: KeyValueSetting;
  RoleCreate: Role;
  RoleDelete?: Maybe<Role>;
  RoleUpdate: Role;
  creatableCreate: Creatable;
  creatableDelete: Creatable;
  remarkTemplateCreate?: Maybe<RemarkTemplate>;
  remarkTemplateDelete?: Maybe<RemarkTemplate>;
  remarkTemplateUpdate?: Maybe<RemarkTemplate>;
  remarkTemplateUpsertOrDeleteMany?: Maybe<Array<RemarkTemplate>>;
  userInformationCreate?: Maybe<UserInformation>;
  userInformationDelete?: Maybe<UserInformation>;
  userInformationUpdate?: Maybe<UserInformation>;
  workCenterCreate?: Maybe<WorkCenter>;
  workCenterDelete?: Maybe<WorkCenter>;
  workCenterUpdate?: Maybe<WorkCenter>;
};


export type MutationCustomTableViewDeleteArgs = {
  input?: InputMaybe<CustomTableViewDeleteInput>;
};


export type MutationCustomTableViewSaveArgs = {
  input?: InputMaybe<CustomTableViewSaveInput>;
};


export type MutationNotificationSettingCreateArgs = {
  input: NotificationSettingCreateInput;
};


export type MutationNotificationSettingUpdateArgs = {
  input: NotificationSettingUpdateInput;
  where: NotificationSettingWhereUniqueInput;
};


export type MutationNotificationUpdateMineApiTokenArgs = {
  input: NotificationUpdateMineApiTokenInput;
};


export type MutationRoleCreateArgs = {
  input: RoleCreateInput;
};


export type MutationRoleDeleteArgs = {
  where: RoleWhereUniqueInput;
};


export type MutationRoleUpdateArgs = {
  input: RoleUpdateInput;
  where: RoleWhereUniqueInput;
};


export type MutationCreatableCreateArgs = {
  createInput: CreatableCreateInput;
};


export type MutationCreatableDeleteArgs = {
  uniqueInput: CreatableUniqueInput;
};


export type MutationRemarkTemplateCreateArgs = {
  createInput: RemarkTemplateCreateInput;
};


export type MutationRemarkTemplateDeleteArgs = {
  uniqueInput: RemarkTemplateUniqueInput;
};


export type MutationRemarkTemplateUpdateArgs = {
  uniqueInput: RemarkTemplateUniqueInput;
  updateInput: RemarkTemplateUpdateInput;
};


export type MutationRemarkTemplateUpsertOrDeleteManyArgs = {
  idsToDelete?: InputMaybe<Array<Scalars['Int']>>;
  upsertManyInput: Array<RemarkTemplateUpsertInput>;
};


export type MutationUserInformationCreateArgs = {
  createInput: UserInformationCreateInput;
};


export type MutationUserInformationDeleteArgs = {
  uniqueInput: UserInformationUniqueInput;
};


export type MutationUserInformationUpdateArgs = {
  uniqueInput: UserInformationUniqueInput;
  updateInput: UserInformationUpdateInput;
};


export type MutationWorkCenterCreateArgs = {
  createInput: WorkCenterCreateInput;
};


export type MutationWorkCenterDeleteArgs = {
  uniqueInput: WorkCenterUniqueInput;
};


export type MutationWorkCenterUpdateArgs = {
  uniqueInput: WorkCenterUniqueInput;
  updateInput: WorkCenterUpdateInput;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  isSet?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedBooleanFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBooleanFilter>;
};

export type NestedBooleanNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  isSet?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBooleanFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
};

export type NestedFloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  isSet?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  isSet?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Scalars['String']>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<StringQueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Scalars['String']>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isSet?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<StringQueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NotificationSetting = {
  __typename?: 'NotificationSetting';
  id: Scalars['Int'];
  meta_data?: Maybe<Scalars['JSON']>;
  module: Scalars['String'];
  name: Scalars['String'];
  provider_name: NotificationSettingProviderName;
  setting?: Maybe<Scalars['JSON']>;
};

export type NotificationSettingCreateInput = {
  meta_data?: InputMaybe<Scalars['JSON']>;
  module: Scalars['String'];
  name: Scalars['String'];
  provider_name: NotificationSettingProviderName;
  setting?: InputMaybe<Scalars['JSON']>;
};

export enum NotificationSettingProviderName {
  Line = 'line'
}

export type NotificationSettingUpdateInput = {
  meta_data?: InputMaybe<Scalars['JSON']>;
  setting?: InputMaybe<Scalars['JSON']>;
};

export type NotificationSettingWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type NotificationUpdateMineApiTokenInput = {
  mine_api_token: Scalars['String'];
};

export enum PermissionBinaryType {
  Allow = 'ALLOW',
  Deny = 'DENY'
}

export enum PermissionBinaryViewType {
  All = 'ALL',
  None = 'NONE'
}

export enum PermissionViewType {
  All = 'ALL',
  None = 'NONE',
  Self = 'SELF'
}

export type Project_AccessPermission = {
  __typename?: 'Project_AccessPermission';
  view: PermissionBinaryType;
};

export type Project_AccessPermissionInput = {
  view: PermissionBinaryType;
};

export type Projects_AccessPermission = {
  __typename?: 'Projects_AccessPermission';
  view: PermissionViewType;
};

export type Projects_AccessPermissionInput = {
  view: PermissionViewType;
};

export type Purchase_AccessPermission = {
  __typename?: 'Purchase_AccessPermission';
  view: PermissionBinaryType;
};

export type Purchase_AccessPermissionInput = {
  view: PermissionBinaryType;
};

export type Purchase_Order_AccessPermission = {
  __typename?: 'Purchase_order_AccessPermission';
  view: PermissionViewType;
};

export type Purchase_Order_AccessPermissionInput = {
  view: PermissionViewType;
};

export type Purchase_Report_AccessPermission = {
  __typename?: 'Purchase_report_AccessPermission';
  view: PermissionBinaryViewType;
};

export type Purchase_Report_AccessPermissionInput = {
  view: PermissionBinaryViewType;
};

export type Purchase_Request_AccessPermission = {
  __typename?: 'Purchase_request_AccessPermission';
  view: PermissionViewType;
};

export type Purchase_Request_AccessPermissionInput = {
  view: PermissionViewType;
};

export type Purchase_Return_AccessPermission = {
  __typename?: 'Purchase_return_AccessPermission';
  view: PermissionViewType;
};

export type Purchase_Return_AccessPermissionInput = {
  view: PermissionViewType;
};

export type Query = {
  __typename?: 'Query';
  NotificationSetting?: Maybe<NotificationSetting>;
  NotificationSettingGetAll?: Maybe<Array<Maybe<NotificationSetting>>>;
  Role?: Maybe<Role>;
  RoleRbacTestView?: Maybe<Scalars['String']>;
  RoleViewAggrid: RoleViewAggridOutput;
  Roles?: Maybe<Array<Maybe<Role>>>;
  S3GetFileUrl: Scalars['Any'];
  S3GetUploadUrl: Scalars['Any'];
  creatable?: Maybe<Creatable>;
  creatables: Array<Creatable>;
  getCustomTableView?: Maybe<CustomTableView>;
  remarkTemplate?: Maybe<RemarkTemplate>;
  remarkTemplates: Array<RemarkTemplate>;
  userInformationAggrid?: Maybe<Array<Maybe<UserInformationAggridOutput>>>;
  userInformations?: Maybe<Array<Maybe<UserInformation>>>;
  userinformation?: Maybe<UserInformation>;
  validateAccesssTokenWithHeader: User;
  workCenter?: Maybe<WorkCenter>;
  workCenters?: Maybe<Array<Maybe<WorkCenter>>>;
};


export type QueryNotificationSettingArgs = {
  where: NotificationSettingWhereUniqueInput;
};


export type QueryRoleArgs = {
  where: RoleWhereUniqueInput;
};


export type QueryRoleViewAggridArgs = {
  aggridInput?: InputMaybe<Scalars['AnyAggridInput']>;
};


export type QueryS3GetFileUrlArgs = {
  keyPath: Scalars['String'];
};


export type QueryS3GetUploadUrlArgs = {
  fileName?: InputMaybe<Scalars['String']>;
};


export type QueryCreatableArgs = {
  uniqueInput: CreatableUniqueInput;
};


export type QueryCreatablesArgs = {
  filterInput?: InputMaybe<CreatableFilterInput>;
};


export type QueryGetCustomTableViewArgs = {
  input?: InputMaybe<CustomTableViewSearchInput>;
};


export type QueryRemarkTemplateArgs = {
  uniqueInput: RemarkTemplateUniqueInput;
};


export type QueryRemarkTemplatesArgs = {
  whereInput?: InputMaybe<RemarkTemplateWhereInput>;
};


export type QueryUserInformationAggridArgs = {
  aggridInput?: InputMaybe<AggridTemplateInput>;
};


export type QueryUserinformationArgs = {
  uniqueInput: UserInformationUniqueInput;
};


export type QueryWorkCenterArgs = {
  uniqueInput: WorkCenterUniqueInput;
};

export type Quotation_AccessPermission = {
  __typename?: 'Quotation_AccessPermission';
  view: PermissionViewType;
};

export type Quotation_AccessPermissionInput = {
  view: PermissionViewType;
};

export type RemarkTemplate = {
  __typename?: 'RemarkTemplate';
  entity_type: EntityTypeEnum;
  id: Scalars['Int'];
  name: Scalars['String'];
  remark_text?: Maybe<Scalars['String']>;
};

export type RemarkTemplateCompoundUniqueInput = {
  entity_type: EntityTypeEnum;
  name: Scalars['String'];
};

export type RemarkTemplateCreateInput = {
  entity_type: EntityTypeEnum;
  name: Scalars['String'];
  remark_text?: InputMaybe<Scalars['String']>;
};

export type RemarkTemplateUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
  name_entity_type?: InputMaybe<RemarkTemplateCompoundUniqueInput>;
};

export type RemarkTemplateUpdateInput = {
  entity_type?: InputMaybe<EntityTypeEnum>;
  name?: InputMaybe<Scalars['String']>;
  remark_text?: InputMaybe<Scalars['String']>;
};

export type RemarkTemplateUpsertInput = {
  entity_type: EntityTypeEnum;
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  remark_text?: InputMaybe<Scalars['String']>;
};

export type RemarkTemplateWhereInput = {
  entity_type?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
};

export type Role = {
  __typename?: 'Role';
  created_date: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  last_updated_date: Scalars['DateTime'];
  name: Scalars['String'];
  permission_list: AccessPermission;
  user_list?: Maybe<Array<UserInformation>>;
  user_unique_id_list?: Maybe<Array<Scalars['String']>>;
};

export type RoleCompany_IdNameCompoundUniqueInput = {
  name: Scalars['String'];
};

export type RoleCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  permission_list: AccessPermissionInput;
  user_unique_id_list?: InputMaybe<Array<Scalars['String']>>;
};

export type RoleUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  permission_list?: InputMaybe<AccessPermissionInput>;
  user_unique_id_list?: InputMaybe<Array<Scalars['String']>>;
};

export type RoleView = {
  __typename?: 'RoleView';
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  total_users: Scalars['Int'];
};

export type RoleViewAggridOutput = {
  __typename?: 'RoleViewAggridOutput';
  count: Scalars['Int'];
  data: Array<RoleView>;
};

export type RoleWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type Role_AccessPermission = {
  __typename?: 'Role_AccessPermission';
  view: PermissionViewType;
};

export type Role_AccessPermissionInput = {
  view: PermissionViewType;
};

export type Sales_AccessPermission = {
  __typename?: 'Sales_AccessPermission';
  view: PermissionBinaryType;
};

export type Sales_AccessPermissionInput = {
  view: PermissionBinaryType;
};

export type Sales_Order_AccessPermission = {
  __typename?: 'Sales_order_AccessPermission';
  view: PermissionViewType;
};

export type Sales_Order_AccessPermissionInput = {
  view: PermissionViewType;
};

export type Sales_Report_AccessPermission = {
  __typename?: 'Sales_report_AccessPermission';
  view: PermissionBinaryViewType;
};

export type Sales_Report_AccessPermissionInput = {
  view: PermissionBinaryViewType;
};

export type Sales_Return_AccessPermission = {
  __typename?: 'Sales_return_AccessPermission';
  view: PermissionViewType;
};

export type Sales_Return_AccessPermissionInput = {
  view: PermissionViewType;
};

export type Setting_AccessPermission = {
  __typename?: 'Setting_AccessPermission';
  view: PermissionBinaryType;
};

export type Setting_AccessPermissionInput = {
  view: PermissionBinaryType;
};

export type Setting_Approval_AccessPermission = {
  __typename?: 'Setting_approval_AccessPermission';
  view: PermissionBinaryViewType;
};

export type Setting_Approval_AccessPermissionInput = {
  view: PermissionBinaryViewType;
};

export type Setting_Company_AccessPermission = {
  __typename?: 'Setting_company_AccessPermission';
  view: PermissionBinaryViewType;
};

export type Setting_Company_AccessPermissionInput = {
  view: PermissionBinaryViewType;
};

export type Setting_Inventory_AccessPermission = {
  __typename?: 'Setting_inventory_AccessPermission';
  view: PermissionBinaryViewType;
};

export type Setting_Inventory_AccessPermissionInput = {
  view: PermissionBinaryViewType;
};

export type Setting_Logistic_AccessPermission = {
  __typename?: 'Setting_logistic_AccessPermission';
  view: PermissionBinaryViewType;
};

export type Setting_Logistic_AccessPermissionInput = {
  view: PermissionBinaryViewType;
};

export type Setting_Manufacture_AccessPermission = {
  __typename?: 'Setting_manufacture_AccessPermission';
  view: PermissionBinaryViewType;
};

export type Setting_Manufacture_AccessPermissionInput = {
  view: PermissionBinaryViewType;
};

export type Setting_Project_AccessPermission = {
  __typename?: 'Setting_project_AccessPermission';
  view: PermissionBinaryViewType;
};

export type Setting_Project_AccessPermissionInput = {
  view: PermissionBinaryViewType;
};

export type Setting_Remark_AccessPermission = {
  __typename?: 'Setting_remark_AccessPermission';
  view: PermissionBinaryViewType;
};

export type Setting_Remark_AccessPermissionInput = {
  view: PermissionBinaryViewType;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export enum Status {
  Accepted = 'accepted',
  Active = 'active',
  Approved = 'approved',
  Cancelled = 'cancelled',
  Completed = 'completed',
  Delivering = 'delivering',
  Draft = 'draft',
  Finished = 'finished',
  FullyImported = 'fully_imported',
  FullyOrdered = 'fully_ordered',
  FullyPaid = 'fully_paid',
  InProgress = 'in_progress',
  Inactive = 'inactive',
  InsufficientIngredient = 'insufficient_ingredient',
  IsMainBom = 'is_main_bom',
  Manufacturing = 'manufacturing',
  NotApproved = 'not_approved',
  NotCompleted = 'not_completed',
  PartiallyImported = 'partially_imported',
  PartiallyOrdered = 'partially_ordered',
  PartiallyPaid = 'partially_paid',
  PendingManu = 'pending_manu',
  Ready = 'ready',
  WaitAccept = 'wait_accept',
  WaitApprove = 'wait_approve',
  WaitDeliver = 'wait_deliver',
  WaitPayment = 'wait_payment',
  WaitPreviousJobs = 'wait_previous_jobs',
  WaitTransfer = 'wait_transfer'
}

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<StringQueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isSet?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<StringQueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableListFilter = {
  equals?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  has?: InputMaybe<Scalars['String']>;
  hasEvery?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  hasSome?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isEmpty?: InputMaybe<Scalars['Boolean']>;
};

export enum StringQueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type User = {
  __typename?: 'User';
  address?: Maybe<Scalars['JSON']>;
  company_list?: Maybe<Array<Maybe<Company>>>;
  company_unique_id_list?: Maybe<Array<Scalars['String']>>;
  created_date: Scalars['DateTime'];
  creator_unique_id?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  first_name: Scalars['String'];
  id: Scalars['Int'];
  img_url?: Maybe<Array<Scalars['String']>>;
  inactive_remarks?: Maybe<Scalars['String']>;
  is_active: Scalars['Int'];
  last_login_date?: Maybe<Scalars['DateTime']>;
  last_login_tenant?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  last_updated_date?: Maybe<Scalars['DateTime']>;
  last_updator_unique_id?: Maybe<Scalars['String']>;
  national_id?: Maybe<Scalars['String']>;
  nick_name?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  title_name?: Maybe<Scalars['String']>;
  unique_id: Scalars['String'];
};

export type UserInformation = {
  __typename?: 'UserInformation';
  created_by: Scalars['JSON'];
  created_date: Scalars['DateTime'];
  department: Scalars['String'];
  id: Scalars['Int'];
  img_url?: Maybe<Array<Scalars['String']>>;
  inactive_remarks?: Maybe<Scalars['String']>;
  last_updated_by?: Maybe<Scalars['JSON']>;
  last_updated_date?: Maybe<Scalars['DateTime']>;
  last_updator_user_id?: Maybe<Scalars['String']>;
  position: Scalars['String'];
  role_id_list?: Maybe<Array<Scalars['Int']>>;
  role_list?: Maybe<Array<Role>>;
  status: Scalars['Int'];
  user_unique_id?: Maybe<Scalars['String']>;
};

export type UserInformationAggridOutput = {
  __typename?: 'UserInformationAggridOutput';
  count: Scalars['Int'];
  results: Array<UserInformation>;
};

export type UserInformationCreateInput = {
  department: Scalars['String'];
  img_url?: InputMaybe<Array<Scalars['String']>>;
  inactive_remarks?: InputMaybe<Scalars['String']>;
  position: Scalars['String'];
  role_id_list?: InputMaybe<Array<Scalars['Int']>>;
  status: Scalars['Int'];
  user_unique_id: Scalars['String'];
};

export type UserInformationUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
  user_unique_id?: InputMaybe<Scalars['String']>;
};

export type UserInformationUpdateInput = {
  department?: InputMaybe<Scalars['String']>;
  img_url?: InputMaybe<Array<Scalars['String']>>;
  inactive_remarks?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['String']>;
  role_id_list?: InputMaybe<Array<Scalars['Int']>>;
  status?: InputMaybe<Scalars['Int']>;
};

export type User_Information_AccessPermission = {
  __typename?: 'User_information_AccessPermission';
  view: PermissionViewType;
};

export type User_Information_AccessPermissionInput = {
  view: PermissionViewType;
};

export type Vendor_AccessPermission = {
  __typename?: 'Vendor_AccessPermission';
  view: PermissionViewType;
};

export type Vendor_AccessPermissionInput = {
  view: PermissionViewType;
};

export type WorkCenter = {
  __typename?: 'WorkCenter';
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  plant: Scalars['String'];
  production_center: Scalars['String'];
  remark?: Maybe<Scalars['String']>;
};

export type WorkCenterCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  plant: Scalars['String'];
  production_center: Scalars['String'];
  remark?: InputMaybe<Scalars['String']>;
};

export type WorkCenterUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type WorkCenterUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  plant?: InputMaybe<Scalars['String']>;
  production_center?: InputMaybe<Scalars['String']>;
  remark?: InputMaybe<Scalars['String']>;
};

export type CreatableCreateMutationVariables = Exact<{
  createInput: CreatableCreateInput;
}>;


export type CreatableCreateMutation = { __typename?: 'Mutation', creatableCreate: { __typename?: 'Creatable', name: string, usage_field_type: EntityTypeEnum, usage_field_name: string } };

export type CreatableDeleteMutationVariables = Exact<{
  uniqueInput: CreatableUniqueInput;
}>;


export type CreatableDeleteMutation = { __typename?: 'Mutation', creatableDelete: { __typename?: 'Creatable', name: string, usage_field_type: EntityTypeEnum, usage_field_name: string } };

export type CreatableQueryVariables = Exact<{
  uniqueInput: CreatableUniqueInput;
}>;


export type CreatableQuery = { __typename?: 'Query', creatable?: { __typename?: 'Creatable', name: string, usage_field_type: EntityTypeEnum, usage_field_name: string } | null };

export type CreatablesQueryVariables = Exact<{
  filterInput?: InputMaybe<CreatableFilterInput>;
}>;


export type CreatablesQuery = { __typename?: 'Query', creatables: Array<{ __typename?: 'Creatable', name: string, usage_field_type: EntityTypeEnum, usage_field_name: string }> };


export const CreatableCreateDocument = `
    mutation CreatableCreate($createInput: CreatableCreateInput!) {
  creatableCreate(createInput: $createInput) {
    name
    usage_field_type
    usage_field_name
  }
}
    `;
export const useCreatableCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CreatableCreateMutation, TError, CreatableCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CreatableCreateMutation, TError, CreatableCreateMutationVariables, TContext>(
      ['CreatableCreate'],
      (variables?: CreatableCreateMutationVariables) => fetcher<CreatableCreateMutation, CreatableCreateMutationVariables>(client, CreatableCreateDocument, variables, headers)(),
      options
    );
export const CreatableDeleteDocument = `
    mutation CreatableDelete($uniqueInput: CreatableUniqueInput!) {
  creatableDelete(uniqueInput: $uniqueInput) {
    name
    usage_field_type
    usage_field_name
  }
}
    `;
export const useCreatableDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CreatableDeleteMutation, TError, CreatableDeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CreatableDeleteMutation, TError, CreatableDeleteMutationVariables, TContext>(
      ['CreatableDelete'],
      (variables?: CreatableDeleteMutationVariables) => fetcher<CreatableDeleteMutation, CreatableDeleteMutationVariables>(client, CreatableDeleteDocument, variables, headers)(),
      options
    );
export const CreatableDocument = `
    query Creatable($uniqueInput: CreatableUniqueInput!) {
  creatable(uniqueInput: $uniqueInput) {
    name
    usage_field_type
    usage_field_name
  }
}
    `;
export const useCreatableQuery = <
      TData = CreatableQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: CreatableQueryVariables,
      options?: UseQueryOptions<CreatableQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<CreatableQuery, TError, TData>(
      ['Creatable', variables],
      fetcher<CreatableQuery, CreatableQueryVariables>(client, CreatableDocument, variables, headers),
      options
    );
export const CreatablesDocument = `
    query Creatables($filterInput: CreatableFilterInput) {
  creatables(filterInput: $filterInput) {
    name
    usage_field_type
    usage_field_name
  }
}
    `;
export const useCreatablesQuery = <
      TData = CreatablesQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: CreatablesQueryVariables,
      options?: UseQueryOptions<CreatablesQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<CreatablesQuery, TError, TData>(
      variables === undefined ? ['Creatables'] : ['Creatables', variables],
      fetcher<CreatablesQuery, CreatablesQueryVariables>(client, CreatablesDocument, variables, headers),
      options
    );
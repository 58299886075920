import {
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
} from "ag-grid-community";
import { TFunction } from "i18next";
import { dateFilterParams } from "../../../../utils/Formatter/AgGridFilter";
import { ISalesOrder } from "../../../../types/Sales/salesOrder";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import { formatNumber } from "../../../../utils/dataTransformer";
import CustomizedAvatar from "../../../Custom/CustomizedAvatar";
import { IAvatar, ICreatedBy, ITag } from "../../../../types/global";
import { formatDate } from "../../../../utils/Formatter/Date";
import { statusValueFormatter } from "../../../../utils/Formatter/Global";
import { useEffect, useState } from "react";

export const useSalesOrderColumnDefs = (t: TFunction, isReport: boolean) => {
  const [columnDef, setColumnDef] = useState<ColDef[]>();

  useEffect(() => {
    if (!isReport) {
      setColumnDef([
        {
          field: "unique_id",
          headerName: t("sales.unique_id"),
          filter: "agTextColumnFilter",
          minWidth: 200,
          flex: 1,
        },
        {
          field: "created_date",
          headerName: t("date.created_date"),
          sort: "desc",
          hide: true,
          filter: "agDateColumnFilter",
          filterParams: dateFilterParams,
          suppressColumnsToolPanel: true,
          minWidth: 200,
          flex: 1,
          valueFormatter: (params: ValueFormatterParams) =>
            formatDate(params.value),
        },
        {
          field: "issue_date",
          headerName: t("date.issue_date"),
          filter: "agDateColumnFilter",
          filterParams: dateFilterParams,
          minWidth: 200,
          flex: 1,
          valueFormatter: (params: ValueFormatterParams) =>
            formatDate(params.value),
        },
        {
          field: "delivery_date",
          headerName: t("date.delivery_date"),
          filter: "agDateColumnFilter",
          filterParams: dateFilterParams,
          minWidth: 200,
          flex: 1,
          valueFormatter: (params: ValueFormatterParams) =>
            formatDate(params.value),
        },
        {
          field: "aggrid_status",
          headerName: t("status.index"),
          sortable: false,
          filter: "agSetColumnFilter",
          cellRenderer: (params: ICellRendererParams<ISalesOrder, string>) => (
            <CustomizedStatus status={params.value} />
          ),
          cellStyle: {
            display: "flex",
            justifycontent: "center",
            alignItems: "center",
          },
          minWidth: 200,
          flex: 1,
          filterParams: {
            values: [
              "draft",
              "wait_approve",
              "not_approved",
              "approved",
              "finished",
              "expired",
              "cancelled",
            ],
            valueFormatter: (params: ValueFormatterParams) =>
              statusValueFormatter(params.value),
          },
        },
        {
          field: "customer_name",
          headerName: t("sales.customer_name"),
          filter: "agTextColumnFilter",
          minWidth: 200,
          flex: 1,
        },
        {
          field: "tax_invoice_number",
          headerName: t("sales.tax_invoice_number"),
          filter: "agTextColumnFilter",
          minWidth: 200,
          flex: 1,
        },
        {
          field: "total_amount",
          filter: "agNumberColumnFilter",
          headerName: t("sales.total_amount"),
          valueFormatter: (params: ValueFormatterParams<ISalesOrder, number>) =>
            formatNumber(params.value),
          minWidth: 225,
          flex: 1,
        },
        {
          field: "created_by_object",
          headerName: t("sales.created_by"),
          filter: "agTextColumnFilter",
          cellRenderer: (params: ICellRendererParams<ISalesOrder, IAvatar>) => {
            if (params.value) {
              return <CustomizedAvatar avatars={[params.value]} />;
            }
          },
          minWidth: 200,
          flex: 1,
        },
        {
          field: "related_user_object_list",
          headerName: t("sales.employee_list"),
          filter: "agSetColumnFilter",
          cellRenderer: (
            params: ICellRendererParams<ISalesOrder, IAvatar[]>
          ) => {
            if (params.value && params.value.length > 0) {
              return <CustomizedAvatar avatars={params.value} />;
            }
          },
          minWidth: 200,
          flex: 1,
        },
      ]);
    } else {
      setColumnDef([
        {
          field: "unique_id",
          headerName: t("sales.unique_id"),
          filter: "agTextColumnFilter",
          minWidth: 200,
          flex: 1,
        },
        {
          field: "issue_date",
          headerName: t("date.issue_date"),
          filter: "agDateColumnFilter",
          filterParams: dateFilterParams,
          minWidth: 200,
          flex: 1,
          valueGetter: (params: ValueGetterParams) =>
            formatDate(params.data.issue_date),
        },
        {
          field: "delivery_date",
          headerName: t("date.delivery_date"),
          filter: "agDateColumnFilter",
          filterParams: dateFilterParams,
          minWidth: 200,
          flex: 1,
          valueGetter: (params: ValueGetterParams) =>
            formatDate(params.data.delivery_date),
        },
        {
          field: "aggrid_status",
          headerName: t("status.index"),
          sortable: true,
          filter: "agSetColumnFilter",
          cellRenderer: (params: ICellRendererParams<ISalesOrder, string>) => (
            <CustomizedStatus status={params.value} />
          ),
          cellStyle: {
            display: "flex",
            justifycontent: "center",
            alignItems: "center",
          },
          minWidth: 200,
          flex: 1,
          filterParams: {
            values: [
              "ร่าง",
              "รออนุมัติ",
              "ไม่อนุมัติ",
              "อนุมัติแล้ว",
              "เสร็จสิ้น",
              "ยกเลิก",
            ],
            valueFormatter: (params: ValueFormatterParams) =>
              statusValueFormatter(params.value),
          },
        },
        {
          field: "project_unique_id",
          headerName: t("sales.project_unique_id"),
          sortable: true,
          filter: "agTextColumnFilter",
          minWidth: 200,
          flex: 1,
          valueGetter: (params: ValueGetterParams) =>
            params.data.project_unique_id || "-",
        },
        {
          field: "project_name",
          headerName: t("sales.project_name"),
          sortable: true,
          filter: "agTextColumnFilter",
          minWidth: 200,
          flex: 1,
          valueGetter: (params: ValueGetterParams) =>
            params.data.project_name || "-",
        },
        {
          field: "customer_unique_id",
          headerName: t("sales.customer_unique_id"),
          sortable: true,
          filter: "agTextColumnFilter",
          minWidth: 200,
          flex: 1,
        },
        {
          field: "customer_name",
          headerName: t("sales.customer_name"),
          sortable: true,
          filter: "agTextColumnFilter",
          minWidth: 200,
          flex: 1,
        },
        {
          field: "tax_invoice_number",
          headerName: t("sales.tax_invoice_number"),
          filter: "agTextColumnFilter",
          minWidth: 200,
          flex: 1,
        },
        {
          field: "payment_type",
          headerName: t("sales.payment_type"),
          sortable: true,
          filter: "agTextColumnFilter",
          minWidth: 200,
          flex: 1,
        },
        {
          field: "sales_channel",
          headerName: t("sales.sales_channel"),
          sortable: true,
          filter: "agSetColumnFilter",
          minWidth: 200,
          flex: 1,
        },
        {
          field: "account_id",
          headerName: t("sales.account_id"),
          sortable: false,
          filter: false,
          minWidth: 200,
          flex: 1,
        },
        {
          field: "tag_list",
          headerName: t("sales.tag_list"),
          sortable: true,
          filter: "agSetColumnFilter",
          minWidth: 200,
          flex: 1,
          valueGetter: (params: ValueGetterParams) =>
            params.data.tag_list && params.data.tag_list.length > 0
              ? params.data.tag_list.map((tag: ITag) => tag.name).join(", ")
              : "-",
        },
        {
          field: "price_vat_type",
          headerName: t("sales.price_vat_type"),
          hide: true,
          suppressColumnsToolPanel: true,
          filter: "agSetColumnFilter",
          minWidth: 200,
          flex: 1,
          valueGetter: (params: ValueGetterParams) => {
            if (params.data.price_vat_type === "excluded_vat") {
              return "ราคาแยกภาษี";
            } else {
              return "ราคารวมภาษี";
            }
          },
        },
        {
          field: "item_unique_id",
          headerName: t("reports.item_document_id"),
          filter: "agTextColumnFilter",
          minWidth: 200,
          flex: 1,
        },
        {
          field: "item_name",
          headerName: t("reports.item_name"),
          filter: "agTextColumnFilter",
          minWidth: 200,
          flex: 1,
        },
        {
          field: "qty",
          headerName: t("reports.qty"),
          filter: false,
          minWidth: 200,
          flex: 1,
          valueGetter: (params: ValueGetterParams) =>
            formatNumber(params.data.qty),
        },
        {
          field: "uom",
          headerName: t("reports.uom"),
          filter: false,
          minWidth: 200,
          flex: 1,
        },
        {
          field: "price_per_unit",
          headerName: t("reports.price_per_unit"),
          filter: false,
          minWidth: 200,
          flex: 1,
          valueGetter: (params: ValueGetterParams) =>
            formatNumber(params.data.price_per_unit),
          cellStyle: { display: "flex", justifyContent: "flex-end" },
        },
        {
          field: "vat_percentage",
          headerName: t("reports.vat_type"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          minWidth: 200,
          flex: 1,
        },
        {
          field: "pre_vat_amount_exclude_discount",
          headerName: t("reports.pre_vat_amount_exclude_discount"),
          filter: false,
          valueGetter: (params: ValueGetterParams) =>
            formatNumber(params.data.pre_vat_amount_exclude_discount),
          cellStyle: { display: "flex", justifyContent: "flex-end" },
          minWidth: 200,
          flex: 1,
        },
        {
          field: "item_discount",
          headerName: t("reports.item_discount"),
          valueGetter: (params: ValueGetterParams) =>
            formatNumber(params.data.item_discount),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          minWidth: 200,
          flex: 1,
        },
        {
          field: "item_additional_discount",
          headerName: t("reports.item_additional_discount"),
          valueGetter: (params: ValueGetterParams) =>
            formatNumber(params.data.item_additional_discount),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          minWidth: 200,
          flex: 1,
        },
        {
          field: "pre_vat_amount_include_discount",
          headerName: t("reports.pre_vat_amount_include_discount"),
          filter: false,
          valueGetter: (params: ValueGetterParams) =>
            formatNumber(params.data.pre_vat_amount_include_discount),
          cellStyle: { display: "flex", justifyContent: "flex-end" },
          minWidth: 200,
          flex: 1,
        },
        {
          field: "item_vat_exempted_amount",
          headerName: t("reports.item_vat_exempted_amount"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          valueGetter: (params: ValueGetterParams) =>
            formatNumber(params.data.item_vat_exempted_amount),
          minWidth: 200,
          flex: 1,
        },
        {
          field: "item_vat_0_amount",
          headerName: t("reports.item_vat_0_amount"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          valueGetter: (params: ValueGetterParams) =>
            formatNumber(params.data.item_vat_0_amount),
          minWidth: 200,
          flex: 1,
        },
        {
          field: "item_vat_7_amount",
          headerName: t("reports.item_vat_7_amount"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          valueGetter: (params: ValueGetterParams) =>
            formatNumber(params.data.item_vat_7_amount),
          minWidth: 200,
          flex: 1,
        },
        {
          field: "item_vat_amount",
          headerName: t("reports.item_vat_amount"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          valueGetter: (params: ValueGetterParams) =>
            formatNumber(params.data.item_vat_amount),
          minWidth: 200,
          flex: 1,
        },
        {
          field: "item_net_amount",
          headerName: t("reports.item_net_amount"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          valueGetter: (params: ValueGetterParams) =>
            formatNumber(params.data.item_net_amount),
          minWidth: 200,
          flex: 1,
        },
        {
          field: "item_withholding_tax_type",
          headerName: t("reports.item_withholding_tax_type"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          minWidth: 200,
          flex: 1,
        },
        {
          field: "item_withholding_tax",
          headerName: t("reports.item_withholding_tax"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          valueGetter: (params: ValueGetterParams) =>
            formatNumber(params.data.item_withholding_tax),
          minWidth: 200,
          flex: 1,
        },
        {
          field: "item_total_amount",
          headerName: t("reports.item_total_amount"),
          filter: false,
          valueGetter: (params: ValueGetterParams) =>
            formatNumber(params.data.item_total_amount),
          cellStyle: { display: "flex", justifyContent: "flex-end" },
          minWidth: 200,
          flex: 1,
        },
        {
          field: "shipping_cost",
          headerName: t("reports.shipping_cost"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          valueGetter: (params: ValueGetterParams) =>
            formatNumber(params.data.shipping_cost),
          minWidth: 200,
          flex: 1,
        },
        {
          field: "total_amount",
          headerName: t("reports.total_amount"),
          valueGetter: (params: ValueGetterParams) =>
            formatNumber(params.data.total_amount),
          cellStyle: { display: "flex", justifyContent: "flex-end" },
          filter: false,
          minWidth: 200,
          flex: 1,
        },
        {
          field: "additional_discount",
          headerName: t("reports.additional_discount"),
          valueGetter: (params: ValueGetterParams) =>
            formatNumber(params.data.additional_discount),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          minWidth: 200,
          flex: 1,
        },
        {
          field: "credit_day",
          headerName: t("reports.credit_day"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          minWidth: 200,
          flex: 1,
        },
        {
          field: "remark",
          headerName: t("reports.remark"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          minWidth: 200,
          flex: 1,
        },
        {
          field: "stock_qty",
          headerName: t("reports.qty_uom"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          valueGetter: (params: ValueGetterParams) =>
            formatNumber(params.data.stock_qty),
          minWidth: 200,
          flex: 1,
        },
        {
          field: "qty_shipped",
          headerName: t("reports.qty_shipped"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          valueGetter: (params: ValueGetterParams) =>
            formatNumber(params.data.qty_shipped),
          minWidth: 200,
          flex: 1,
        },
        {
          field: "qty_to_ship",
          headerName: t("reports.qty_to_ship"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          valueGetter: (params: ValueGetterParams) =>
            formatNumber(params.data.qty_to_ship),
          minWidth: 200,
          flex: 1,
        },
        {
          field: "qty_invoiced",
          headerName: t("reports.qty_invoiced"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          valueGetter: (params: ValueGetterParams) =>
            formatNumber(params.data.qty_invoiced),
          minWidth: 200,
          flex: 1,
        },
        {
          field: "qty_returned",
          headerName: t("reports.qty_returned"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          valueGetter: (params: ValueGetterParams) =>
            formatNumber(params.data.qty_returned),
          minWidth: 200,
          flex: 1,
        },

        {
          field: "is_late",
          headerName: t("reports.is_late"),
          hide: true,
          filter: "agSetColumnFilter",
          filterParams: {
            values: ["ยังไม่เกินเวลา", "เกินเวลาแล้ว"],
          },
          minWidth: 200,
          flex: 1,
        },
        {
          field: "reference_unique_id_list",
          headerName: t("reports.reference_document_id"),
          hide: true,
          filter: "agSetColumnFilter",
          minWidth: 200,
          flex: 1,
          valueGetter: (params: ValueGetterParams) =>
            params.data.reference_unique_id_list &&
            params.data.reference_unique_id_list.length > 0
              ? params.data.reference_unique_id_list
                  .map((reference_unique_id: string) => reference_unique_id)
                  .join(", ")
              : "-",
        },
        {
          field: "external_reference_id",
          headerName: t("reports.external_ref_id"),
          hide: true,
          filter: "agTextColumnFilter",
          minWidth: 200,
          flex: 1,
        },
        {
          field: "due_date",
          headerName: t("date.due_date"),
          filter: "agDateColumnFilter",
          filterParams: dateFilterParams,
          minWidth: 200,
          flex: 1,
          hide: true,
          valueGetter: (params: ValueGetterParams) =>
            formatDate(params.data.due_date),
        },
        {
          field: "item_description",
          headerName: t("reports.item_description"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          minWidth: 200,
          flex: 1,
        },
        {
          field: "item_remark",
          headerName: t("reports.item_remark"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          minWidth: 200,
          flex: 1,
        },
        {
          field: "created_date",
          headerName: t("reports.created_date"),
          filter: "agDateColumnFilter",
          hide: true,
          minWidth: 200,
          flex: 1,
          filterParams: dateFilterParams,
          valueGetter: (params: ValueGetterParams) =>
            formatDate(params.data.created_date),
        },
        {
          field: "updated_date",
          headerName: t("reports.updated_date"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          minWidth: 200,
          flex: 1,
          filterParams: dateFilterParams,
          valueGetter: (params: ValueGetterParams) =>
            formatDate(params.data.updated_date),
        },
        {
          field: "created_by",
          headerName: t("reports.created_by"),
          filter: "agTextColumnFilter",
          minWidth: 200,
          flex: 1,
          valueGetter: (params: ValueGetterParams) =>
            params.data.created_by.first_name +
            " " +
            params.data.created_by.last_name,
        },
        {
          field: "related_user_list",
          headerName: t("reports.employee_list"),
          filter: "agTextColumnFilter",
          minWidth: 200,
          flex: 1,
          valueGetter: (params: ValueGetterParams) =>
            params.data.related_user_list &&
            params.data.related_user_list.length > 0
              ? params.data.related_user_list
                  .map(
                    (user: ICreatedBy) => user.first_name + " " + user.last_name
                  )
                  .join(", ")
              : "-",
        },
        {
          field: "sales_contact_list",
          headerName: t("reports.sales_contact_list"),
          filter: "agTextColumnFilter",
          minWidth: 200,
          flex: 1,
          valueGetter: (params: ValueGetterParams) =>
            params.data.sales_contact_list &&
            params.data.sales_contact_list.length > 0
              ? params.data.sales_contact_list
                  .map(
                    (user: ICreatedBy) => user.first_name + " " + user.last_name
                  )
                  .join(", ")
              : "-",
        },
        {
          field: "email",
          headerName: t("reports.email"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          minWidth: 200,
          flex: 1,
        },
        {
          field: "phone",
          headerName: t("reports.phone"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          minWidth: 200,
          flex: 1,
        },
        {
          field: "fax",
          headerName: t("reports.fax"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          minWidth: 200,
          flex: 1,
        },
        {
          field: "billing_address",
          headerName: t("reports.billing_address"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          minWidth: 200,
          flex: 1,
        },
        {
          field: "billing_sub_district",
          headerName: t("reports.billing_sub_district"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          minWidth: 200,
          flex: 1,
        },
        {
          field: "billing_district",
          headerName: t("reports.billing_district"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          minWidth: 200,
          flex: 1,
        },
        {
          field: "billing_province",
          headerName: t("reports.billing_province"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          minWidth: 200,
          flex: 1,
        },
        {
          field: "billing_postal_code",
          headerName: t("reports.billing_postal_code"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          minWidth: 200,
          flex: 1,
        },
        {
          field: "billing_country",
          headerName: t("reports.billing_country"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          minWidth: 200,
          flex: 1,
        },
        {
          field: "is_same_as_default_address",
          headerName: t("reports.is_same_as_default_address"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          minWidth: 200,
          flex: 1,
        },
        {
          field: "delivery_address_contact_name",
          headerName: t("reports.delivery_address_contact_name"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          minWidth: 200,
          flex: 1,
        },
        {
          field: "delivery_address_contact_phone",
          headerName: t("reports.delivery_address_contact_phone"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          minWidth: 200,
          flex: 1,
        },
        {
          field: "delivery_address",
          headerName: t("reports.delivery_address"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          minWidth: 200,
          flex: 1,
        },
        {
          field: "delivery_sub_district",
          headerName: t("reports.delivery_sub_district"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          minWidth: 200,
          flex: 1,
        },
        {
          field: "delivery_district",
          headerName: t("reports.delivery_district"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          minWidth: 200,
          flex: 1,
        },
        {
          field: "delivery_province",
          headerName: t("reports.delivery_province"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          minWidth: 200,
          flex: 1,
        },
        {
          field: "delivery_postal_code",
          headerName: t("reports.delivery_postal_code"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          minWidth: 200,
          flex: 1,
        },
        {
          field: "delivery_country",
          headerName: t("reports.delivery_country"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          minWidth: 200,
          flex: 1,
        },
      ]);
    }
  }, [isReport, t]);
  return columnDef;
};

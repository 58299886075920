import { ColDef, ValueFormatterParams } from "ag-grid-community";
import { TFunction } from "i18next";
import { Box, Typography } from "@mui/material";
import { formatDate } from "../../../../utils/Formatter/Date";
import { formatNumber } from "../../../../utils/dataTransformer";
import { dateFilterParams } from "../../../../utils/Formatter/AgGridFilter";
import { statusValueFormatter } from "../../../../utils/Formatter/Global";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import { ICellRendererParams } from "ag-grid";
import { useEffect, useMemo, useState } from "react";
import { RefObject } from "@fullcalendar/core/preact";
import { AgGridReact } from "ag-grid-react";
import { ISalesItemPriceHistoryList } from "../../../../types/Sales";

interface Props {
  t: TFunction;
  disabled: boolean;
  documentFilter: string | null;
  gridRef: RefObject<AgGridReact<ISalesItemPriceHistoryList | any>>;
  onFilterChanged: (params: any) => void;
}

export const useSalesItemPriceHistoryColumnDefs = (
  t: Props["t"],
  disabled: Props["disabled"],
  documentFilter: Props["documentFilter"],
  gridRef: Props["gridRef"]
): ColDef[] => {
  const defaultColumns = useMemo<ColDef[]>(
    () => [
      {
        field: "document_unique_id",
        headerName: t("sales.unique_id"),
        filter: "agTextColumnFilter",
        checkboxSelection: !disabled,
      },
      {
        field: "document_type",
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["quotation", "sales_order"],
        },
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "customer_name",
        headerName: t("sales.customer_name"),
        filter: "agTextColumnFilter",
      },
      {
        field: "customer_contact_unique_id",
        filter: "agTextColumnFilter",
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "document_issue_date",
        headerName: t("date.issue_date"),
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        minWidth: 200,
        flex: 1,
        valueFormatter: (params: ValueFormatterParams) =>
          formatDate(params.value),
        sort: "desc",
      },
      {
        field: "item_unique_id",
        filter: "agTextColumnFilter",
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "item_name",
        headerName: t("inventory.items.name"),
        filter: "agTextColumnFilter",
      },
      {
        field: "price_per_unit",
        headerName: t("reports.price_per_unit"),
        headerClass: "ag-right-aligned-header",
        filter: false,
        cellRenderer: (params: any) => {
          return (
            <Box width={"100%"} textAlign="right">
              <Typography fontSize={14}>
                {formatNumber(params.value)}
              </Typography>
            </Box>
          );
        },
        cellStyle: {
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        },
      },
      {
        field: "qty",
        headerName: t("inventory.quantity"),
        headerClass: "ag-right-aligned-header",
        filter: false,
        cellRenderer: (params: any) => {
          return (
            <Box
              // vertically center the box
              sx={{
                display: "flex",
                width: "100%",
                textAlign: "right",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography fontSize={14}>
                {formatNumber(params.value)}
              </Typography>
              <Typography variant="caption" color={"#737373"}>
                {params.data.uom}
              </Typography>
            </Box>
          );
        },
      },
      {
        field: "discount",
        headerName: t("reports.discount_amount_per_unit"),
        headerClass: "ag-right-aligned-header",
        filter: "agNumberColumnFilter",
        cellRenderer: (params: any) => {
          return (
            <Box
              // vertically center the box
              sx={{
                display: "flex",
                width: "100%",
                textAlign: "right",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography fontSize={14}>
                {formatNumber(params.value)}
              </Typography>
              <Typography variant="caption" color={"#737373"}>
                {params.data.discount_type &&
                params.data.discount_type === "baht"
                  ? "บาท"
                  : "%"}
              </Typography>
            </Box>
          );
        },
      },
      {
        field: "vat_percentage",
        headerName: "ภาษี",
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["7", "0", "ไม่มี"],
          valueFormatter: (params: ValueFormatterParams) => {
            switch (params.value) {
              case "7":
                return "7 %";
              case "0":
                return "0 %";
              case "ไม่มี":
                return "ไม่มี";
              default:
                return params.value;
            }
          },
        },
        valueFormatter: (params: ValueFormatterParams) => {
          switch (params.value) {
            case "7":
              return "7 %";
            case "0":
              return "0 %";
            case "ไม่มี":
              return "ไม่มี";
            default:
              return params.value;
          }
        },
      },
      {
        field: "remark",
        headerName: t("reports.item_remark"),
        filter: "agTextColumnFilter",
      },
    ],
    [disabled, t]
  );

  const [columnDefs, setColumnDefs] = useState<ColDef[]>(defaultColumns);

  useEffect(() => {
    if (documentFilter) {
      if (documentFilter === "quotation") {
        const newColumnDefs: ColDef[] = [
          ...defaultColumns,
          {
            field: "aggrid_status",
            headerName: t("status.index"),
            filter: "agSetColumnFilter",
            filterParams: {
              values: [
                "draft",
                "wait_approve",
                "not_approved",
                "wait_accept",
                "accepted",
                "expired",
                "finished",
                "cancelled",
              ],
              valueFormatter: (params: ValueFormatterParams) =>
                statusValueFormatter(params.value),
            },
            cellRenderer: (params: ICellRendererParams) => (
              <CustomizedStatus status={params.value} />
            ),
            cellStyle: {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
          },
        ];
        if (gridRef && gridRef.current) {
          gridRef.current!.api.setColumnDefs([]);
          gridRef.current!.api.setColumnDefs(newColumnDefs);
        }
        setColumnDefs(newColumnDefs);
      } else {
        const newColumnDefs: ColDef[] = [
          ...defaultColumns,
          {
            field: "aggrid_status",
            headerName: t("status.index"),
            filter: "agSetColumnFilter",
            filterParams: {
              values: [
                "draft",
                "wait_approve",
                "not_approved",
                "approved",
                "expired",
                "finished",
                "cancelled",
              ],
              valueFormatter: (params: ValueFormatterParams) =>
                statusValueFormatter(params.value),
            },
            cellRenderer: (params: ICellRendererParams) => (
              <CustomizedStatus status={params.value} />
            ),
            cellStyle: {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
          },
        ];
        if (gridRef && gridRef.current) {
          gridRef.current!.api.setColumnDefs([]);
          gridRef.current!.api.setColumnDefs(newColumnDefs);
        }
        setColumnDefs(newColumnDefs);
      }
    }
  }, [defaultColumns, documentFilter, gridRef, t]);

  return columnDefs;
};

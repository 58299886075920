import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu, ITab } from "../../../types/global";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import { Typography } from "@mui/material";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import HeaderLayout from "../../../components/UI/HeaderLayout";
import PurchaseOrderTable, { PurchaseOrderTableType } from "../../../components/Table/Purchase/Order";
import CustomizedMenuOptions from "../../../components/Custom/CustomizedMenuOptions";
import ButtonLayout from "../../../components/UI/ButtonLayout";
import { ConfigurableAgGridReact } from "../../../components/UI/ConfigurableAgGrid/ConfigurableAgGrid";
import { IPurchaseOrder } from "../../../types/Purchase/purchaseOrder";
import { useRef } from "react";
import ConfigurableAgGridEditButton from "../../../components/UI/ConfigurableAgGrid/ConfigurableAgGridEditButton";

const PurchaseOrder = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");

  const tableRef = useRef<PurchaseOrderTableType>()

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("purchase.index"),
      to: "/purchase",
    },
    {
      name: t("purchase.order.index"),
    },
  ];

  const tabs: ITab[] = [
    {
      label: t("global.all"),
      path: `${pathname}`,
    },
    {
      label: t("status.draft"),
      path: `${pathname}?filter=draft`,
    },
    {
      label: t("status.wait_approve"),
      path: `${pathname}?filter=wait_approve`,
    },
    {
      label: t("status.not_approved"),
      path: `${pathname}?filter=not_approved`,
    },
    {
      label: t("status.approved"),
      path: `${pathname}?filter=approved`,
    },
    {
      label: t("status.partially_imported"),
      path: `${pathname}?filter=partially_imported`,
    },
    {
      label: t("status.fully_imported"),
      path: `${pathname}?filter=fully_imported`,
    },
    {
      label: t("status.cancelled"),
      path: `${pathname}?filter=cancelled`,
    },
  ];

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout>
        <Typography variant="h5">{t("purchase.order.index")}</Typography>
        <ButtonLayout>
          <CustomizedButton
            title={`${t("button.create")}${t("purchase.order.index")}`}
            variant="contained"
            onClick={() => navigate(`${pathname}/add`)}
          />
          <ConfigurableAgGridEditButton tableRef={tableRef}/>
        </ButtonLayout>
      </HeaderLayout>
      <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
      <PurchaseOrderTable isFilter={isFilter} ref={tableRef}/>
    </>
  );
};

export default PurchaseOrder;

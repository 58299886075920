import { Typography } from "@mui/material";
import { AgGridReact, AgGridReactProps } from "ag-grid-react";
import { forwardRef, ReactNode, useCallback, useEffect, useImperativeHandle, useReducer, useState } from "react";
import AG_GRID_LOCALE_TH from "../../../locale.th";
import CustomDate from "../CustomDate";
import AgGrid from "../AgGrid";
import { ColDef, GridReadyEvent, ColumnState, ColGroupDef, ColumnApi, GridApi, ColumnMovedEvent, ColumnPinnedEvent, ColumnResizedEvent, ColumnValueChangedEvent, ColumnVisibleEvent, FilterChangedEvent, SortChangedEvent } from "ag-grid-community";
import CustomViewActionStatusPanel from "./CustomViewActionStatusPanel";
import CustomViewMessageStatusPanel from "./CustomViewMessageStatusPanel";
import { clear } from "console";
import { useStateContext } from "../../../contexts/auth-context";
import zIndex from "@mui/material/styles/zIndex";

interface Props {
  isClientSide?: boolean;
  autoHeight?: boolean;
  suppressMenu?: boolean;
  disabledSidebar?: boolean;
  disableFloatingFilter?: boolean;
  headerClass?: [];
  disableResized?: boolean;
  paginationSize?: number;
  height?: number | string;
  width?: number | string;
  children?: ReactNode;
  path?: string;
  persistKey: string;
  ignoreFilterKey?: string[]
}

type ExtendProps = AgGridReactProps & Props;

export type ConfigurableAgGridReact<T> = AgGridReact<T> & {
  setMode: (mode: 'everyone' | 'onlyme' | undefined) => void
}

const ConfigurableAgGrid = forwardRef<any, ExtendProps>(
  (
    {
      onGridReady,
      columnDefs: initialColumnDefs,
      persistKey,
      ignoreFilterKey,
      onColumnMoved,
      onColumnPinned,
      onColumnResized,
      onColumnValueChanged,
      onColumnVisible,
      onFilterChanged,
      onSortChanged,
      ...rest
    },
    ref
  ) => {
    const [editMode, seteditMode] = useState<'everyone' | 'onlyme'>()
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const [isColumnDirty, setisColumnDirty] = useState(false)
    const [isFilterDirty, setisFilterDirty] = useState(false)
    const {
      state: { authUser },
    } = useStateContext();

    const [customizedColumnDefs, setcustomizedColumnDefs] = useState<(ColDef<any> | ColGroupDef<any>)[] | null>()
    const [customizedFilterModel, setcustomizedFilterModel] = useState<{
      [key: string]: any;
    } | null>(null);
    const [originalFilterModel, setoriginalFilterModel] = useState<{
      [key: string]: any;
    } | null>(null);
    const [gridApi, setgridApi] = useState<GridApi>()
    const [columnApi, setcolumnApi] = useState<ColumnApi>()

    useImperativeHandle(ref, () => {
      return {
        setMode: (mode: 'everyone' | 'onlyme') => {
          seteditMode(mode)
          setisColumnDirty(false)
          setisFilterDirty(false)
        },
        api: gridApi
      };
    }, [gridApi])

    const getSavedCustomizedColumnDefs = async (edit: 'everyone' | 'onlyme' | undefined = editMode ,mode: 'everyone' | 'onlyme' | undefined = undefined) => {
      const everyOneData = localStorage.getItem(`${persistKey}-column-def-everyone`)
      const onlymeData = localStorage.getItem(`${persistKey}-column-def-onlyme-${authUser?.unique_id}`)
      if (edit) {
        debugger
        const data = {
          everyone: everyOneData,
          onlyme: onlymeData
        }
        let defaultColumnDefs = null
        // force select mode
        if(mode) {
          return data[mode] ? JSON.parse(data[mode] || '') : null
        }

        if (edit === 'onlyme') {
          defaultColumnDefs = everyOneData ? JSON.parse(everyOneData) : null
        }
        const result = data[edit]
        return result ? JSON.parse(result) : defaultColumnDefs
      }
      if (onlymeData) {
        return JSON.parse(onlymeData)
      }
      if (everyOneData) {
        return JSON.parse(everyOneData)
      }
      return null
    }
    const getSavedCustomizedFilterModel = async () => {
      const everyOneData = localStorage.getItem(`${persistKey}-filter-model-everyone`)
      const onlymeData = localStorage.getItem(`${persistKey}-filter-model-onlyme-${authUser?.unique_id}`)
      if (editMode) {
        const data = {
          everyone: everyOneData,
          onlyme: onlymeData
        }
        const result = data[editMode]
        return result ? JSON.parse(result) : null
      }
      if (onlymeData) {
        return JSON.parse(onlymeData)
      }
      if (everyOneData) {
        return JSON.parse(everyOneData)
      }
      return null
    }

    const saveCustomizedColumnDefs = async (columnDefs: ColDef[], mode: string) => {
      if (mode === 'everyone') {
        localStorage.setItem(`${persistKey}-column-def-${mode}`, JSON.stringify(columnDefs))
      } else {
        localStorage.setItem(`${persistKey}-column-def-${mode}-${authUser?.unique_id}`, JSON.stringify(columnDefs))
      }
      return Promise.resolve()
    }

    const saveCustomizedFilterModel = async (filterModel: {
      [key: string]: any;
    }, mode: string) => {
      const filter = filterModel
      if (ignoreFilterKey) {
        ignoreFilterKey.forEach(key => {
          delete filter[key]
        })
      }
      if (mode === 'everyone') {
        localStorage.setItem(`${persistKey}-filter-model-${mode}`, JSON.stringify(filter))
      } else {
        localStorage.setItem(`${persistKey}-filter-model-${mode}-${authUser?.unique_id}`, JSON.stringify(filter))
      }
      return Promise.resolve()
    }

    // const clearCustomizedColumnDefs = async (mode: string) => {
    //   if (mode === 'everyone') {
    //     localStorage.removeItem(`${persistKey}-column-def-${mode}`)
    //   } else {
    //     localStorage.removeItem(`${persistKey}-column-def-${mode}-${authUser?.unique_id}`)
    //   }
    //   return Promise.resolve()
    // }

    // const clearCustomizedFilterModel = async (mode: string) => {
    //   if (mode === 'everyone') {
    //     localStorage.removeItem(`${persistKey}-filter-model-${mode}`)
    //   } else {
    //     localStorage.removeItem(`${persistKey}-filter-model-${mode}-${authUser?.unique_id}`)
    //   }
    //   return Promise.resolve()
    // }

    const setFilter = (filterModel: {
      [key: string]: any;
    }) => {
      const originalFilterModel = gridApi?.getFilterModel()
      const filter = {
        ...originalFilterModel,
        ...filterModel,
      }
      gridApi?.setFilterModel(filter)
    }

    // load saved config
    useEffect(() => {
      const getSavedData = async () => {
        const savedCustomizedColumnDefs = await getSavedCustomizedColumnDefs()
        const savedCustomizedFilterModel = await getSavedCustomizedFilterModel()
        setcustomizedColumnDefs(savedCustomizedColumnDefs)
        setcustomizedFilterModel(savedCustomizedFilterModel)
      }
      getSavedData()
    }, [editMode, gridApi])

    useEffect(() => {
      if (customizedFilterModel) {
        setFilter(customizedFilterModel)
      }
    }, [customizedFilterModel])


    useEffect(() => { // sync state to status panel
      if (!gridApi) {
        return
      }
      const customViewActionInstance = gridApi.getStatusPanel<any>('customViewAction')
      const customViewMessageInstance = gridApi.getStatusPanel<any>('customViewMessage')
      customViewActionInstance?.setMode(editMode)
      customViewActionInstance?.setColumnDirty(isColumnDirty || !!customizedColumnDefs)
      customViewActionInstance?.setFilterDirty(isFilterDirty || !!customizedFilterModel)
      customViewMessageInstance?.setMode(editMode)
    }, [editMode, gridApi, isColumnDirty, isFilterDirty, customizedColumnDefs, customizedFilterModel])

    const sideBar = {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
            // suppressSideButtons: true,
            suppressColumnFilter: true,
            suppressColumnSelectAll: true,
            // suppressColumnExpandAll: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          toolPanelParams: {
            suppressExpandAll: true,
          },
        },
      ],
    };

    const convertColumnStateToColumnDef = (columnState: ColumnState[]): ColDef[] => {
      const columnDefs = columnState.map(state => {
        // Find base definition if needed for extra info (like headerName, field, etc.)
        const baseDef = initialColumnDefs && initialColumnDefs.find((def: ColDef) => def.field === state.colId) as ColDef<any> || {};
        
        return {
          ...baseDef,
          colId: state.colId,
          width: state.width,
          flex: baseDef.width === state.width ? baseDef.flex : undefined,
          hide: state.hide,
          sort: state.sort,
          sortIndex: state.sortIndex,
          pinned: state.pinned
        } as ColDef;
      });
      return columnDefs
    }

    const applyDefaultColumnDef = (newColDef: ColDef[]): ColDef[] | undefined => {
      if (!newColDef) {
        return undefined
      }
      const columnDefs = newColDef.map(col => {
        // Find base definition if needed for extra info (like headerName, field, etc.)
        const baseDef = initialColumnDefs && initialColumnDefs.find((def: ColDef) => def.field === col.field) as ColDef<any> || {};
        const def = {
          ...baseDef,
          ...col,
        } as ColDef;
        return def
      });
      return columnDefs
    }
    const saveColumnState = async (columnApi: ColumnApi, mode: string, isDirty: boolean) => {
      console.log(isDirty)
      if (isDirty) { //save data
        // get current column size
        const columnState = columnApi.getColumnState()
        const newColumnDef = convertColumnStateToColumnDef(columnState)
        // setcustomizedColumnDefs(columnState)
        return saveCustomizedColumnDefs(newColumnDef, mode)
      } else { // save clear
        return deleteCustomizedColumnDefs(mode)
      }
    }

    const deleteCustomizedColumnDefs = async (mode: string) => {
      if (mode === 'everyone') {
        localStorage.removeItem(`${persistKey}-column-def-${mode}`)
      } else {
        localStorage.removeItem(`${persistKey}-column-def-${mode}-${authUser?.unique_id}`)
      }
      return Promise.resolve()
    }

    const saveFilterModel = async (gridApi: GridApi, mode: string, isDirty: boolean) => {
      if (isDirty) { // save data
        // get current filter
        const filterModel = gridApi.getFilterModel()
        return saveCustomizedFilterModel(filterModel, mode)
      } else { // save clear
        deleteCustomizedFilterModel(mode)
      }
    }

    const deleteCustomizedFilterModel = async (mode: string) => {
      if (mode === 'everyone') {
        localStorage.removeItem(`${persistKey}-filter-model-${mode}`)
      } else {
        localStorage.removeItem(`${persistKey}-filter-model-${mode}-${authUser?.unique_id}`)
      }
      return Promise.resolve()
    }

    const exitEditMode = async (api: GridApi) => {
      // reload column def and filter mode
      const columnDef = await getSavedCustomizedColumnDefs()
      const filterModel = await getSavedCustomizedFilterModel()
      setcustomizedColumnDefs(columnDef)
      setFilter(filterModel)
      seteditMode(undefined)
    }

    const saveConfig = async (api: GridApi, columnApi: ColumnApi, mode: string, isColumnDirty: boolean, isFilterDirty: boolean) => {
      if (!api || !columnApi) {
        return Promise.reject('API not ready')
      }
      await Promise.all([saveColumnState(columnApi, mode, isColumnDirty), saveFilterModel(api, mode, isFilterDirty)])

      // reload column def and filter mode
      await exitEditMode(api)
    }

    const clearConfig = async (api: GridApi, columnApi: ColumnApi, editMode: 'everyone' | 'onlyme') => {
      if (!api || !columnApi) {
        return Promise.reject('API not ready')
      }
      let columnDef = null
      if (editMode === 'onlyme') {
        columnDef = await getSavedCustomizedColumnDefs(editMode, 'everyone')
      }
      setcustomizedColumnDefs(columnDef)
      setcustomizedFilterModel(null)
      setisColumnDirty(false) // also rerender component
      setisFilterDirty(false) // also rerender component
      console.log('clear config')
      // await Promise.all([clearCustomizedColumnDefs(mode), clearCustomizedFilterModel(mode)])
      
      // reload column def and filter mode
      // await exitEditMode(api)
    }

    const statusBar = {
      statusPanels: [
        {
          key: 'customViewAction',
          statusPanel: CustomViewActionStatusPanel,
          statusPanelParams: {
            onReset: clearConfig,
            onApply: saveConfig,
            editMode: editMode,
            onCancel: exitEditMode,
            isColumnDirty,
            isFilterDirty
          },
          align: "right",
        },
        {
          key: 'customViewMessage',
          statusPanel: CustomViewMessageStatusPanel,
          statusPanelParams: {
          },
          align: "left",
        },
      ]
    }
    // on grid ready
    const onGridReadyCallback = async (event: GridReadyEvent) => {
      setgridApi(event.api)
      setcolumnApi(event.columnApi)
      setFilter(customizedFilterModel || {})
      onGridReady && await onGridReady(event)
    }

    const onColumnMovedHandle = (e: ColumnMovedEvent) => {
      onColumnMoved && onColumnMoved(e)
      console.log(e)
      if (e.source !== 'gridOptionsChanged') { 
        setisColumnDirty(true)
      }
    }
    const onColumnPinnedHandle = (e: ColumnPinnedEvent) => {
      onColumnPinned && onColumnPinned(e)
      if (e.source !== 'gridOptionsChanged') { 
        setisColumnDirty(true)
      }
    }
    const onColumnResizedHandle = (e: ColumnResizedEvent) => {
      onColumnResized && onColumnResized(e)
      if (e.source !== 'gridOptionsChanged') { 
        setisColumnDirty(true)
      }
    }
    const onColumnValueChangedHandle = (e: ColumnValueChangedEvent) => {
      onColumnValueChanged && onColumnValueChanged(e)
      if (e.source !== 'gridOptionsChanged') { 
        setisColumnDirty(true)
      }
    }
    const onColumnVisibleHandle = (e: ColumnVisibleEvent) => {
      onColumnVisible && onColumnVisible(e)
      if (e.source !== 'gridOptionsChanged') { 
        setisColumnDirty(true)
      }
    }
    const onFilterChangedHandle = (e: FilterChangedEvent) => {
      onFilterChanged && onFilterChanged(e)
      setisFilterDirty(true)
    }
    const onSortChangedHandle = (e: SortChangedEvent) => {
      onSortChanged && onSortChanged(e)
    }
    console.log(isColumnDirty)
    return <>
      {editMode && <div style={{position: 'absolute', zIndex: 5, width: '100vw', height: '100vh', top: 0, left: 0, backgroundColor: 'rgba(0,0,0,0.1)'}}></div>}
      <AgGrid
        {...rest}
        columnDefs={customizedColumnDefs ? applyDefaultColumnDef(customizedColumnDefs) : applyDefaultColumnDef(initialColumnDefs as ColDef[])}
        onGridReady={onGridReadyCallback}
        ref={ref}
        sideBar={sideBar}
        statusBar={statusBar}
        containerStyle={editMode && {
          zIndex: 6,
          position: 'absolute',
          width: '100%'
          // top: 0, left: 0, right: 0, bottom: 0
        }}
        onColumnMoved={onColumnMovedHandle}
        onColumnPinned={onColumnPinnedHandle}
        onColumnResized={onColumnResizedHandle}
        onColumnValueChanged={onColumnValueChangedHandle}
        onColumnVisible={onColumnVisibleHandle}
        onFilterChanged={onFilterChangedHandle}
        onSortChanged={onSortChangedHandle}
      />
    </>
  }
);

export default ConfigurableAgGrid;

import {
  IDefaultForm,
  IMenuOption,
  ISelectOption,
} from "../../../../types/global";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import {
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { Controller, useFieldArray } from "react-hook-form";
import TocIcon from "@mui/icons-material/Toc";
import ItemNameCell from "./ItemNameCell";
import ControlledTextField from "../../../Controller/ControlledTextField";
import ControlledSelect from "../../../Controller/ControlledSelect";
import ControlledNumberTextField from "../../../Controller/ControlledNumberTextField";
import PreVatAmountCell from "./PreVatAmountCell";
import WithholdingTaxCell from "./WithholdingTaxCell";
import CloseIcon from "@mui/icons-material/Close";
import { useRef, useState } from "react";
import Confirmation from "../../../UI/Confirmation";
import CustomizedButton from "../../../Custom/CustomizedButton";
import AddIcon from "@mui/icons-material/Add";
import { useModal } from "../../../../hooks/use-modal";
import { AgGridReact } from "ag-grid-react";
import { EntityTypeEnum } from "../../../../generated/creatable";
import { formatNumber } from "../../../../utils/dataTransformer";
import SumPreVatAmount from "./SumPreVatAmount";
import { IDeliveryOrderItemList } from "../../../../types/Logistic/deliveryOrder";
import { useDeliveryOrderItemListHeader } from "../../../../hooks/Logistic/use-delivery-order-item-list-header";
import ItemTableModal from "../../ItemTableModal";
import { v4 as uuidv4 } from "uuid";
import { ISkuDetail } from "../../../../types/Inventory/item";
import { AdditionalDiscountType } from "../../../../generated/logistic";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CustomizedIconMenuButton from "../../../Custom/CustomizedIconMenuOptions";

interface Props {
  control: IDefaultForm["control"];
  getValues: IDefaultForm["getValues"];
  setValue: IDefaultForm["setValue"];
  errors: IDefaultForm["errors"];
  disabled: IDefaultForm["disabled"];
  documentType: EntityTypeEnum;
  referenceItemList?: IDeliveryOrderItemList[];
}

type FieldArrayType = {
  item_list: IDeliveryOrderItemList[];
};

const vatOptions: ISelectOption[] = [
  {
    label: "ไม่มี",
    value: "ไม่มี",
  },
  {
    label: "0 %",
    value: "0",
  },
  {
    label: "7 %",
    value: "7",
  },
];

const additionalDiscountTypeOptions: IMenuOption[] = [
  {
    value: "บาท",
  },
  {
    value: "%",
  },
];

const DeliveryOrderItemList = ({
  control,
  errors,
  disabled,
  documentType,
  getValues,
  setValue,
  referenceItemList,
}: Props) => {
  const gridRefItemList = useRef<AgGridReact<ISkuDetail>>(null);
  const headers = useDeliveryOrderItemListHeader(disabled);

  const [openDeleteItemConfirmation, setOpenDeleteItemConfirmation] =
    useState<boolean>(false);
  const [deletedIndex, setDeletedIndex] = useState<number | undefined>(
    undefined
  );

  const {
    modal: itemModal,
    openModalHandler: openItemModalHandler,
    closeModalHandler: closeItemModalHandler,
  } = useModal();

  const { fields, move, remove, append } = useFieldArray<
    FieldArrayType,
    "item_list",
    "id"
  >({
    control,
    name: "item_list",
  });

  const dragEndHandler = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    move(result.source.index, result.destination.index);
  };

  const openDeleteItemConfirmationHandler = (index: number) => {
    setOpenDeleteItemConfirmation(true);
    setDeletedIndex(index);
  };

  const closeDeleteItemConfirmationHandler = () => {
    setOpenDeleteItemConfirmation(false);
    setDeletedIndex(undefined);
  };

  const deleteItemConfirmationAction = () => {
    remove(deletedIndex);
    closeDeleteItemConfirmationHandler();
  };

  const addItemsHandler = () => {
    const selectedItems = gridRefItemList?.current?.api.getSelectedRows();
    if (selectedItems) {
      const document_unique_id = getValues("unique_id");
      selectedItems.forEach((item) => {
        if (item.item_sku && item.item_sku.item) {
          let vat_type;
          switch (item.item_sku.item.sale_vat_type) {
            case "0 %":
            case "0":
              vat_type = "0";
              break;
            case "7 %":
            case "7":
              vat_type = "7";
              break;
            default:
              vat_type = "ไม่มี";
          }
          let formatItems: IDeliveryOrderItemList = {
            unique_id: uuidv4(),
            item_unique_id: item.sku_name,
            item_name: item.item_sku.item.name,
            item_sku_desc: item.item_sku.item.desc,
            qty: 1,
            discount: 0,
            discount_type: "baht",
            sales_uom: item.item_sku.item.sales_uom_unique_id,
            pre_vat_amount: 0,
            pre_discount_amount: 0,
            price_per_unit:
              item.sale_price ?? item.item_sku.item.sale_price ?? 0,
            uom: item.item_sku.item.stock_uom_unique_id,
            remark: "",
            stock_qty: 1,
            vat_percentage: vat_type,
            withholding_tax_type: "ยังไม่ระบุ",
            withholding_tax_value: 0,
            reference_unique_id: document_unique_id,
            uom_group: item.item_sku.item.uom_group,
            // item_img_url: item.img_url,
            // tracability: item.item_sku.item.tracability,
            // barcode: item.barcode || undefined,
          };
          append(formatItems);
        }
      });
    }
    closeItemModalHandler();
  };

  return (
    <CustomizedBox padding={0} margin={0} sx={{ breakAfter: "auto" }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 650, overflow: "scroll" }}
          aria-label="simple table"
        >
          <TableHead
            sx={{ backgroundColor: (theme) => theme.palette.primary.light }}
          >
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  align="center"
                  key={index}
                  sx={{
                    px: 1,
                    py: 1,
                  }}
                  width={header.width}
                >
                  <Typography
                    fontSize={14}
                    fontWeight={600}
                    width={header.width}
                  >
                    {header.thaiLabel}
                  </Typography>
                  <Typography
                    fontSize={14}
                    fontWeight={600}
                    width={header.width}
                  >
                    {header.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={dragEndHandler}>
            <Droppable droppableId="droppable" isDropDisabled={disabled}>
              {(provided) => (
                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                  {fields.map((field, index) => (
                    <Draggable
                      key={field.id}
                      draggableId={"item-" + field.id}
                      index={index}
                      isDragDisabled={disabled}
                    >
                      {(provided) => (
                        <TableRow
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          sx={{
                            ...provided.draggableProps.style,
                            breakInside: "avoid",
                          }}
                        >
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[0]?.width || 0
                                : 0) + 16
                            }
                          >
                            <Box
                              sx={{
                                display: "flex",
                                gap: 1,
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {!disabled && <TocIcon fontSize="small" />}
                              <Typography fontSize={14}>{index + 1}</Typography>
                            </Box>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[1]?.width || 0
                                : 0) + 16
                            }
                          >
                            <ItemNameCell
                              control={control}
                              errors={errors}
                              field={field}
                              index={index}
                              disabled={disabled}
                              documentType={documentType}
                            />
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[2]?.width || 0
                                : 0) + 16
                            }
                          >
                            {disabled ? (
                              <Typography fontSize={14}>{field.qty}</Typography>
                            ) : (
                              <ControlledTextField
                                control={control}
                                name={`item_list[${index}].qty`}
                                type="number"
                                error={Boolean(
                                  errors?.item_list &&
                                    errors?.item_list[index] &&
                                    errors?.item_list[index]?.qty
                                )}
                                helperText={
                                  errors?.item_list &&
                                  errors?.item_list[index] &&
                                  errors?.item_list[index]?.qty &&
                                  errors?.item_list[index]?.qty.message
                                }
                                onChange={(e, fieldHook) => {
                                  const parseValue = parseInt(e.target.value);
                                  fieldHook.onChange(parseValue);
                                  const currentUomUniqueId = getValues(
                                    `item_list[${index}].uom`
                                  );
                                  if (currentUomUniqueId) {
                                    if (field.uom_group) {
                                      if (
                                        currentUomUniqueId ===
                                        field.uom_group.base_uom?.unique_id
                                      ) {
                                        setValue(
                                          `item_list[${index}].stock_qty`,
                                          parseValue
                                        );
                                      } else {
                                        if (
                                          field.uom_group.uom_conversion_list &&
                                          field.uom_group.uom_conversion_list
                                            .length > 0
                                        ) {
                                          const conversionUom =
                                            field.uom_group.uom_conversion_list.find(
                                              (conversion) =>
                                                conversion.target_uom_unique_id ===
                                                currentUomUniqueId
                                            );
                                          if (conversionUom) {
                                            const targetUomQty =
                                              conversionUom.base_uom_rate /
                                              conversionUom.target_uom_rate;
                                            setValue(
                                              `item_list[${index}].stock_qty`,
                                              parseValue * targetUomQty
                                            );
                                          }
                                        }
                                      }
                                    }
                                  }
                                }}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[3]?.width || 0
                                : 0) + 16
                            }
                          >
                            {disabled ? (
                              <Typography fontSize={14}>{field.uom}</Typography>
                            ) : (
                              <ControlledSelect
                                control={control}
                                name={`item_list[${index}].uom`}
                                error={Boolean(
                                  errors?.item_list &&
                                    errors?.item_list[index] &&
                                    errors?.item_list[index]?.uom
                                )}
                                helperText={
                                  errors?.item_list &&
                                  errors?.item_list[index] &&
                                  errors?.item_list[index]?.uom &&
                                  errors?.item_list[index]?.uom?.message
                                }
                                options={
                                  field.uom_group
                                    ? field.uom_group.convertable_uom_list &&
                                      field.uom_group.convertable_uom_list
                                        .length > 0
                                      ? [
                                          {
                                            label:
                                              field.uom_group?.base_uom?.name ||
                                              "",
                                            value:
                                              field.uom_group?.base_uom
                                                ?.unique_id || "",
                                          },
                                          ...field.uom_group.convertable_uom_list.map(
                                            (uom) => ({
                                              label: uom.name,
                                              value: uom.unique_id,
                                            })
                                          ),
                                        ]
                                      : [
                                          {
                                            label:
                                              field.uom_group?.base_uom?.name ||
                                              "",
                                            value:
                                              field.uom_group?.base_uom
                                                ?.unique_id || "",
                                          },
                                        ]
                                    : []
                                }
                                onChange={(e: any) => {
                                  const currentQty = getValues(
                                    `item_list[${index}].qty`
                                  );
                                  if (field.uom_group) {
                                    if (
                                      e.target.value ===
                                      field.uom_group.base_uom?.unique_id
                                    ) {
                                      setValue(
                                        `item_list[${index}].stock_qty`,
                                        currentQty
                                      );
                                    } else {
                                      if (
                                        field.uom_group.uom_conversion_list &&
                                        field.uom_group.uom_conversion_list
                                          .length > 0
                                      ) {
                                        const conversionUom =
                                          field.uom_group.uom_conversion_list.find(
                                            (conversion) =>
                                              conversion.target_uom_unique_id ===
                                              e.target.value
                                          );
                                        if (conversionUom) {
                                          const targetUomQty =
                                            conversionUom.base_uom_rate /
                                            conversionUom.target_uom_rate;
                                          setValue(
                                            `item_list[${index}].stock_qty`,
                                            currentQty * targetUomQty
                                          );
                                        }
                                      }
                                    }
                                  }
                                }}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[4]?.width || 0
                                : 0) + 16
                            }
                          >
                            {disabled ? (
                              <Typography fontSize={14}>
                                {formatNumber(field.price_per_unit)}
                              </Typography>
                            ) : (
                              <ControlledNumberTextField
                                control={control}
                                name={`item_list[${index}].price_per_unit`}
                                error={Boolean(
                                  errors?.item_list &&
                                    errors?.item_list[index] &&
                                    errors?.item_list[index]?.price_per_unit
                                )}
                                helperText={
                                  errors?.item_list &&
                                  errors?.item_list[index] &&
                                  errors?.item_list[index]?.price_per_unit &&
                                  errors?.item_list[index]?.price_per_unit
                                    .message
                                }
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[5]?.width || 0
                                : 0) + 16
                            }
                          >
                            {disabled ? (
                              <Typography fontSize={14}>
                                {formatNumber(field.discount)}
                              </Typography>
                            ) : (
                              <ControlledNumberTextField
                                control={control}
                                name={`item_list[${index}].discount`}
                                error={Boolean(
                                  errors?.item_list &&
                                    errors?.item_list[index] &&
                                    errors?.item_list[index]?.discount
                                )}
                                helperText={
                                  errors?.item_list &&
                                  errors?.item_list[index] &&
                                  errors?.item_list[index]?.discount &&
                                  errors?.item_list[index]?.discount.message
                                }
                                endAdorment={
                                  <>
                                    <Controller
                                      name={`item_list[${index}].discount_type`}
                                      control={control}
                                      render={({ field }) => (
                                        <>
                                          {field.value ===
                                            AdditionalDiscountType.Percent && (
                                            <Typography
                                              fontSize={14}
                                              color="#737373"
                                              marginLeft={1}
                                            >
                                              %
                                            </Typography>
                                          )}
                                        </>
                                      )}
                                    />
                                    <CustomizedIconMenuButton
                                      icon={<ArrowDropDownIcon />}
                                      options={additionalDiscountTypeOptions}
                                      onSelect={(e) => {
                                        const target = e.target as HTMLElement;
                                        if (!setValue) return;
                                        if (e.target) {
                                          setValue(
                                            `item_list[${index}].discount_type`,
                                            target.textContent === "บาท"
                                              ? AdditionalDiscountType.Baht
                                              : AdditionalDiscountType.Percent
                                          );
                                        }
                                        setValue(
                                          `item_list[${index}].discount`,
                                          0
                                        );
                                      }}
                                      sx={{
                                        marginLeft: 1,
                                        marginRight: "-14px",
                                        backgroundColor: "#F2F2F2",
                                        borderLeft: "#E2E2E2 solid 1px",
                                        borderRadius: "0px 4px 4px 0",
                                        padding: "8px",
                                      }}
                                      disabled={disabled}
                                      disableRipple
                                    />
                                  </>
                                }
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[6]?.width || 0
                                : 0) + 16
                            }
                          >
                            {disabled ? (
                              <Typography fontSize={14}>
                                {field.vat_percentage}
                              </Typography>
                            ) : (
                              <ControlledSelect
                                control={control}
                                name={`item_list[${index}].vat_percentage`}
                                error={Boolean(
                                  errors?.item_list &&
                                    errors?.item_list[index] &&
                                    errors?.item_list[index]?.vat_percentage
                                )}
                                helperText={
                                  errors?.item_list &&
                                  errors?.item_list[index] &&
                                  errors?.item_list[index]?.vat_percentage &&
                                  errors?.item_list[index]?.vat_percentage
                                    ?.message
                                }
                                options={vatOptions}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[7]?.width || 0
                                : 0) + 16
                            }
                          >
                            <PreVatAmountCell
                              control={control}
                              index={index}
                              field={field}
                              disabled={disabled}
                              setValue={setValue}
                            />
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[8]?.width || 0
                                : 0) + 16
                            }
                          >
                            <WithholdingTaxCell
                              control={control}
                              errors={errors}
                              index={index}
                              documentType={documentType}
                              disabled={disabled}
                              setValue={setValue}
                              field={field}
                            />
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[9]?.width || 0
                                : 0) + 16
                            }
                          >
                            {disabled ? (
                              <>
                                <Typography fontSize={14}>
                                  {field.stock_qty}
                                </Typography>
                                <Typography variant="caption">
                                  {field.uom_group?.base_uom?.name}
                                </Typography>
                              </>
                            ) : (
                              <ControlledTextField
                                sx={{ mt: 3 }}
                                type="number"
                                control={control}
                                name={`item_list[${index}].stock_qty`}
                                helperText={field.uom_group?.base_uom?.name}
                                InputProps={{
                                  inputProps: {
                                    style: { textAlign: "center" },
                                  },
                                }}
                                disabled={true}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[10]?.width || 0
                                : 0) + 16
                            }
                          >
                            {disabled ? (
                              <Typography fontSize={14}>
                                {field.remark}
                              </Typography>
                            ) : (
                              <ControlledTextField
                                control={control}
                                name={`item_list[${index}].remark`}
                                error={Boolean(
                                  errors?.item_list &&
                                    errors?.item_list[index] &&
                                    errors?.item_list[index]?.remark
                                )}
                                helperText={
                                  errors?.item_list &&
                                  errors?.item_list[index] &&
                                  errors?.item_list[index]?.remark &&
                                  errors?.item_list[index]?.remark.message
                                }
                              />
                            )}
                          </TableCell>
                          {!disabled && (
                            <TableCell
                              align="center"
                              sx={{
                                px: 1,
                                py: 1,
                              }}
                              width={
                                (headers && headers.length > 0
                                  ? headers[11]?.width || 0
                                  : 0) + 16
                              }
                            >
                              <IconButton
                                aria-label="delete"
                                size="small"
                                sx={{ ml: 1, color: "rgba(0, 0, 0, 0.54)" }}
                                onClick={() =>
                                  openDeleteItemConfirmationHandler(index)
                                }
                              >
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </TableCell>
                          )}
                        </TableRow>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>
      <Grid container p={3}>
        <Grid item xs={5}>
          {!disabled && (
            <CustomizedButton
              title="เพิ่มสินค้า"
              startIcon={<AddIcon />}
              variant="outlined"
              onClick={openItemModalHandler}
            />
          )}
        </Grid>
        <Grid item xs={7}>
          <SumPreVatAmount control={control} />
        </Grid>
      </Grid>
      <Confirmation
        title="ยืนยันการลบสินค้า"
        open={openDeleteItemConfirmation}
        handleClose={closeDeleteItemConfirmationHandler}
        action={deleteItemConfirmationAction}
      />
      <ItemTableModal
        ref={gridRefItemList}
        itemModal={itemModal}
        closeItemModalHandler={closeItemModalHandler}
        addItemsHandler={addItemsHandler}
      />
    </CustomizedBox>
  );
};

export default DeliveryOrderItemList;

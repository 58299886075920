import { GraphQLClient } from "graphql-request";
import { useCallback, useEffect, useState } from "react";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import { ISalesItemList } from "../../types/Sales";
import { ITEMS_SKU_AGGRID } from "../../services/AgGrid/InventoryAgGrid";
import { IUomGroup } from "../../types/Inventory/item";

export const useSalesItemListFormatter = (
  item_list: ISalesItemList[],
  document_type: string
) => {
  const [formatItemList, setFormatItemList] = useState<ISalesItemList[]>([]);

  const getUomName = useCallback(async () => {
    const graphQLClientWithHeaderItem: GraphQLClient =
      createGraphQLClientWithMiddleware("item");
    if (item_list && item_list.length > 0) {
      const allItemListUniqueId = item_list.map((item) => item?.item_unique_id);

      const { itemSkuDetailsFindManyAggrid } =
        await graphQLClientWithHeaderItem.request(ITEMS_SKU_AGGRID, {
          aggridInput: {
            startRow: 0,
            endRow: allItemListUniqueId.length,
            filterModel: {
              sku_name: {
                filterType: "set",
                values: allItemListUniqueId,
              },
            },
          },
        });

      let formatItemList: ISalesItemList[] = [];

      const { data: itemSkuDetails } = await itemSkuDetailsFindManyAggrid;
      item_list.forEach((item) => {
        const foundItemIndex = itemSkuDetails.findIndex(
          (realItem: any) => realItem.sku_name === item.item_unique_id
        );
        const uom_group: IUomGroup | undefined =
          itemSkuDetails[foundItemIndex]?.item_sku.item.uom_group;
        let stock_qty: number = 1;

        if (document_type === "sales_return") {
          if (uom_group) {
            if (item.uom !== uom_group.base_uom?.unique_id) {
              if (
                uom_group.uom_conversion_list &&
                uom_group.uom_conversion_list.length > 0
              ) {
                const conversionUom = uom_group.uom_conversion_list.find(
                  (conversion) => conversion.target_uom_unique_id === item.uom
                );
                if (conversionUom) {
                  const targetUomQty =
                    conversionUom.base_uom_rate / conversionUom.target_uom_rate;
                  stock_qty = targetUomQty;
                }
              }
            }
          }
          formatItemList.push({
            ...item,
            qty: 1,
            so_qty: item.qty,
            uom_group,
            stock_qty,
          });
        } else {
          formatItemList.push({
            ...item,
            uom_group,
          });
        }
      });
      return formatItemList;
    } else {
      return [];
    }
  }, [document_type, item_list]);

  useEffect(() => {
    const fetcher = async () => {
      const formatWithUom = await getUomName();
      setFormatItemList(formatWithUom);
    };
    fetcher();
  }, [getUomName]);

  return formatItemList;
};

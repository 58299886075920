import { GraphQLClient } from "graphql-request";
import { EntityTypeEnum } from "../../generated/creatable";
import { InventoryDocumentType } from "../../generated/inventory";
import { SalesDocumentType, SalesOrderQuery } from "../../generated/sales";
import { ITEMS_SKU_AGGRID } from "../../services/AgGrid/InventoryAgGrid";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import { ISalesItemList } from "../../types/Sales";
import { ISalesOrder } from "../../types/Sales/salesOrder";
import { ICreatedBy } from "../../types/global";
import { itemListToTraceEntryListFormatter } from "./Global";
import { ITEM_STOCK_UOMS } from "../../services/AgGrid/InventoryAgGrid";
import { v4 as uuidv4 } from "uuid";

export const salesOrderCreatePayloadFormatter = (
  data: ISalesOrder,
  status: string
) => {
  const {
    id,
    item_list,
    customer_contact,
    tag_list,
    created_date,
    ...otherData
  } = data;
  const formatTagList = tag_list ? tag_list.map((tag: any) => tag.name) : [];
  const formatItemList = item_list.map(({ uom_group, ...otherItem }) => ({
    ...otherItem,
    reference_document_type: SalesDocumentType.SalesOrder,
    reference_unique_id: data.unique_id,
  }));

  const { unique_id_name, ...customer } = customer_contact;
  const formatPayload = {
    ...otherData,
    shipping_cost: otherData.shipping_cost || 0,
    additional_discount: otherData.additional_discount || 0,
    customer_contact: customer,
    item_list: formatItemList,
    tag_list: formatTagList,
    sub_status: status,
  };
  return formatPayload;
};

export const salesOrderUpdatePayloadFormatter = (
  data: ISalesOrder,
  status: string,
  isNotApprove?: boolean
) => {
  const {
    id,
    unique_id,
    main_status,
    flag_status,
    aggrid_status,
    customer_contact,
    updated_date,
    item_list,
    tag_list,
    created_by,
    created_date,
    external_reference_id,
    external_reference_id_confirmation,
    tax_invoice_number,
    tax_invoice_date,
    ...otherData
  } = data;
  const { unique_id_name, ...customer } = customer_contact;

  const formatItemList = item_list.map(({ uom_group, ...otherItem }) => ({
    ...otherItem,
    reference_document_type: SalesDocumentType.SalesOrder,
    reference_unique_id: data.unique_id,
    qty_invoiced: status === "finished" ? otherItem.qty : 0,
  }));

  const formatTagList = tag_list ? tag_list.map((tag: any) => tag.name) : [];

  let formatExternalReferenceId: string | undefined = external_reference_id;

  if (
    external_reference_id_confirmation &&
    external_reference_id_confirmation.trim().length > 0
  ) {
    if (external_reference_id && external_reference_id.trim().length > 0) {
      const externalToArray = external_reference_id.trim().split(",");
      const newExternalConfirmation = [
        ...externalToArray,
        external_reference_id_confirmation,
      ];
      const formatNewExternal = newExternalConfirmation.join(", ");
      formatExternalReferenceId = formatNewExternal;
    } else {
      formatExternalReferenceId = external_reference_id_confirmation;
    }
  }

  const formatPayload = {
    ...otherData,
    shipping_cost: otherData.shipping_cost || 0,
    additional_discount: otherData.additional_discount || 0,
    customer_contact: customer,
    flag_status:
      isNotApprove && flag_status
        ? !flag_status.find((fl) => fl === "not_approved")
          ? [...flag_status, "not_approved"]
          : flag_status
        : undefined,
    item_list: formatItemList,
    tag_list: formatTagList,
    sub_status: status,
    external_reference_id:
      status === "finished" ? formatExternalReferenceId : external_reference_id,
  };
  return formatPayload;
};

export const salesOrderQueryFormatter = async (data: ISalesOrder) => {
  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");
  const allItemListUniqueId = data.item_list.map((item) => item.item_unique_id);
  const { itemSkuDetailsFindManyAggrid } =
    await graphQLClientWithHeaderItem.request(ITEMS_SKU_AGGRID, {
      aggridInput: {
        startRow: 0,
        endRow: allItemListUniqueId.length,
        filterModel: {
          sku_name: {
            filterType: "set",
            values: allItemListUniqueId,
          },
        },
      },
    });
  const { data: itemSkuDetails } = await itemSkuDetailsFindManyAggrid;

  let formatItemList: ISalesItemList[] = [];

  data.item_list.forEach((item) => {
    const foundItemIndex = itemSkuDetails.findIndex(
      (realItem: any) => realItem.sku_name === item.item_unique_id
    );

    formatItemList.push({
      ...item,
      uom_group: itemSkuDetails[foundItemIndex]?.item_sku.item.uom_group,
    });
  });

  const formatCustomer = {
    ...data.customer_contact,
    unique_id_name: `${data.customer_contact_unique_id} - ${data.customer_contact.name}`,
  };

  const formatPayload = {
    ...data,
    customer_contact: formatCustomer,
    item_list: formatItemList,
  };
  return formatPayload;
};

export const copySalesOrderFormatter = (
  data?: SalesOrderQuery["salesOrder"]
) => {
  if (data) {
    const {
      id,
      unique_id,
      created_date,
      issue_date,
      due_date,
      delivery_date,
      created_by,
      external_reference_id,
      aggrid_status,
      main_status,
      sub_status,
      flag_status,
      item_list,
      updated_date,
      reference_unique_id_list,
      tax_invoice_number,
      tax_invoice_date,
      ...otherData
    } = data;

    const formatItemList =
      item_list &&
      item_list.map(
        ({
          qty_invoiced,
          qty_returned,
          qty_shipped,
          qty_to_ship,
          ...otherItemList
        }) => ({
          ...otherItemList,
          unique_id: uuidv4(),
        })
      );

    return {
      ...otherData,
      copied_id: id,
      copied_unique_id: unique_id,
      item_list: formatItemList,
    };
  }
};

export const createSalesReturnFromOrder = (data: ISalesOrder) => {
  if (data) {
    const {
      id,
      created_by,
      unique_id,
      created_date,
      issue_date,
      due_date,
      delivery_date,
      aggrid_status,
      main_status,
      sub_status,
      flag_status,
      item_list,
      updated_date,
      tax_invoice_number,
      tax_invoice_date,
      payment_type,
      ...otherData
    } = data;

    const formatItemList =
      item_list &&
      item_list.map(
        ({
          reference_document_type,
          reference_unique_id,
          qty,
          ...otherItemList
        }) => {
          const { uom_group, uom } = otherItemList;
          let stock_qty: number = 1;
          if (uom_group) {
            if (uom !== uom_group.base_uom?.unique_id) {
              if (
                uom_group.uom_conversion_list &&
                uom_group.uom_conversion_list.length > 0
              ) {
                const conversionUom = uom_group.uom_conversion_list.find(
                  (conversion) => conversion.target_uom_unique_id === uom
                );
                if (conversionUom) {
                  const targetUomQty =
                    conversionUom.base_uom_rate / conversionUom.target_uom_rate;
                  stock_qty = targetUomQty;
                }
              }
            }
          }

          return {
            ...otherItemList,
            qty: 1,
            so_qty: qty,
            stock_qty: stock_qty,
          };
        }
      );

    return {
      ...otherData,
      reference_unique_id_list: [data.unique_id],
      item_list: formatItemList,
    };
  }
};

export const createPurchaseRequestFromOrder = (data: ISalesOrder) => {
  if (data) {
    const {
      id,
      created_by,
      unique_id,
      created_date,
      issue_date,
      due_date,
      delivery_date,
      aggrid_status,
      main_status,
      sub_status,
      flag_status,
      item_list,
      updated_date,
      sales_contact_list,
      customer_contact,
      customer_contact_unique_id,
      account_id,
      tag_list,
      sales_channel,
      additional_discount,
      additional_discount_type,
      credit_day,
      net_amount,
      pre_vat_amount,
      price_vat_type,
      shipping_cost,
      sub_total,
      total_amount,
      vat_0_amount,
      vat_7_amount,
      vat_amount,
      vat_exempted_amount,
      withholding_tax_amount,
      tax_invoice_number,
      tax_invoice_date,
      payment_type,
      ...otherData
    } = data;

    const formatItemList =
      item_list &&
      item_list.map(
        ({
          reference_document_type,
          reference_unique_id,
          qty_invoiced,
          qty_ordered,
          qty_shipped,
          qty_to_ship,
          qty_returned,
          qty_manufactured,
          so_qty,
          volume,
          weight,
          unique_id,
          ...otherItemList
        }) => ({
          ...otherItemList,
          qty_ordered: 0,
          reference_line_item: {
            reference_document_type: SalesDocumentType.SalesOrder,
            reference_unique_id: unique_id,
          },
        })
      );

    return {
      ...otherData,
      reference_unique_id_list: [data.unique_id],
      item_list: formatItemList,
    };
  }
};

export const createGoodsIssueFromOrder = async (
  data: ISalesOrder,
  currentUser: ICreatedBy
) => {
  if (data) {
    const { unique_id, item_list } = data;

    const item_unique_id_list = item_list.map(
      (item: any) => item.item_unique_id
    );

    const graphQLClientWithHeaderItem: GraphQLClient =
      createGraphQLClientWithMiddleware("item");

    const { itemStockUoms } = await graphQLClientWithHeaderItem.request(
      ITEM_STOCK_UOMS,
      {
        itemUniqueIdList: item_unique_id_list,
      }
    );

    const itemListWithStockUoms = item_list.map((item: any) => {
      const foundItemWithStockUom = itemStockUoms.find(
        (stockUom: any) => stockUom.unique_id === item.item_unique_id
      );

      return {
        ...item,
        uom: foundItemWithStockUom?.stock_uom?.unique_id ?? item.uom,
      };
    });

    const formatTraceEntry = itemListToTraceEntryListFormatter(
      itemListWithStockUoms,
      InventoryDocumentType.GoodsIssue,
      EntityTypeEnum.SalesOrder
    );

    return {
      type: "sales_order",
      reference_unique_id: unique_id,
      trace_entry_list: formatTraceEntry,
      created_by: currentUser,
    };
  }
};

export const createDeliveryOrderFromOrder = (data: ISalesOrder) => {
  if (data) {
    const {
      id,
      created_by,
      unique_id,
      created_date,
      issue_date,
      due_date,
      aggrid_status,
      main_status,
      sub_status,
      flag_status,
      item_list,
      updated_date,
      sales_contact_list,
      account_id,
      tag_list,
      sales_channel,
      additional_discount,
      additional_discount_type,
      credit_day,
      net_amount,
      pre_vat_amount,
      price_vat_type,
      shipping_cost,
      sub_total,
      total_amount,
      vat_0_amount,
      vat_7_amount,
      vat_amount,
      vat_exempted_amount,
      withholding_tax_amount,
      template_remark_id,
      tax_invoice_number,
      tax_invoice_date,
      payment_type,
      ...otherData
    } = data;

    const formatItemList =
      item_list &&
      item_list.map(
        ({
          reference_document_type,
          reference_unique_id,
          qty_invoiced,
          qty_ordered,
          qty_issued,
          qty_shipped,
          qty_to_ship,
          qty_returned,
          qty_manufactured,
          so_qty,
          barcode,
          item_img_url,
          tracability,
          item_type,
          unique_id,
          ...otherItemList
        }) => ({
          ...otherItemList,
          unique_id: uuidv4(),
          sales_qty: otherItemList.qty,
          sales_uom: otherItemList.uom,
          reference_line_item: {
            reference_document_type: SalesDocumentType.SalesOrder,
            reference_unique_id: unique_id,
          },
        })
      );

    return {
      ...otherData,
      reference_unique_id_list: [data.unique_id],
      item_list: formatItemList,
    };
  }
};

export const createManufactureOrderFromOrder = (
  data: ISalesOrder,
  itemList: ISalesItemList,
  currentUser: ICreatedBy
) => {
  const {
    id,
    created_by,
    unique_id,
    created_date,
    issue_date,
    due_date,
    aggrid_status,
    main_status,
    sub_status,
    flag_status,
    item_list,
    updated_date,
    sales_contact_list,
    account_id,
    tag_list,
    sales_channel,
    additional_discount,
    additional_discount_type,
    credit_day,
    net_amount,
    pre_vat_amount,
    price_vat_type,
    shipping_cost,
    sub_total,
    total_amount,
    vat_0_amount,
    vat_7_amount,
    vat_amount,
    vat_exempted_amount,
    withholding_tax_amount,
    template_remark_id,
    customer_contact,
    tax_invoice_number,
    tax_invoice_date,
    payment_type,
    ...otherData
  } = data;

  const {
    unique_id: item_uuid,
    item_unique_id,
    item_name,
    item_sku_desc,
    uom,
    remark,
  } = itemList;

  return {
    ...otherData,
    reference_unique_id_list: [data.unique_id],
    reference_item_uuid: item_uuid,
    item_unique_id,
    item_name,
    item_description: item_sku_desc,
    uom,
    item_remark: remark,
    production_qty: itemList.qty,
    created_by: currentUser,
  };
};

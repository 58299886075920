import {
  Alert,
  Autocomplete,
  Box,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import ModalUI from "./ModalUI";
import CustomizedButton from "../Custom/CustomizedButton";
import ControlledTextField from "../Controller/ControlledTextField";
import { useTranslation } from "react-i18next";
import { Controller, useForm, useWatch } from "react-hook-form";
import dayjs, { Dayjs } from "dayjs";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  SalesOrderQuery,
  SalesTaxInvoiceNumberQuery,
  useSalesOrderSetTaxInvoiceNumberMutation,
  useSalesTaxInvoiceNumberQuery,
} from "../../generated/sales";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import ControlledDatePicker from "../Controller/ControlledDatePicker";
import { CustomizedTooltip } from "../Custom/CustomizedTooltip";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";

interface ITaxInvoice {
  tax_invoice_date: Dayjs | Date;
  tax_invoice_prefix: string;
  tax_invoice_number: string;
}

const taxInvoiceValidation = Yup.object().shape({
  tax_invoice_date: Yup.date()
    .transform((value, originalValue) => {
      return originalValue ? new Date(originalValue) : null;
    })
    .required("กรุณาระบุวันที่ใบกำกับภาษี"),
  tax_invoice_prefix: Yup.string().required("กรุณาระบุอักษรนำ"),
  tax_invoice_number: Yup.string()
    .required("กรุณาระบุเลขที่ใบกำกับภาษี")
    .max(15, `กรอกได้ไม่เกิน 15 ตัวอักษร`),
});

type Props = {
  taxInvoiceNumberModal: boolean;
  closeTaxInvoiceNumberModal: () => void;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<SalesOrderQuery, unknown>>;
};

const defaultPrefixOptions: any[] = [
  {
    label: "INV",
    value: "INV",
  },
  {
    label: "IVC",
    value: "IVC",
  },
  // {
  //   label: "กำหนดเอง",
  //   value: "",
  // },
];

const TaxInvoiceModal = ({
  taxInvoiceNumberModal,
  closeTaxInvoiceNumberModal,
  refetch,
}: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [openDate, setOpenDate] = useState<boolean>(false);

  const {
    control: taxInvoiceControl,
    handleSubmit: taxInvoiceHandleSubmit,
    getValues: taxInvoiceGetValues,
    setValue: taxInvoiceSetValue,
    formState: { errors: taxInvoiceErrors },
    reset,
  } = useForm<ITaxInvoice>({
    defaultValues: {
      tax_invoice_date: dayjs().startOf("day"),
      tax_invoice_prefix: "INV",
      tax_invoice_number: "",
    },
    resolver: yupResolver(taxInvoiceValidation),
  });

  const taxPrefix = useWatch({
    control: taxInvoiceControl,
    name: "tax_invoice_prefix",
  });

  const graphQLClientWithHeaderSales =
    createGraphQLClientWithMiddleware("sales");

  const { refetch: refetchTaxNumber } =
    useSalesTaxInvoiceNumberQuery<SalesTaxInvoiceNumberQuery>(
      graphQLClientWithHeaderSales,
      {
        prefix: taxPrefix,
      },
      {
        enabled: false,
      }
    );

  const onClose = () => {
    reset();
    closeTaxInvoiceNumberModal();
  };

  const generateUniqueId = useCallback(async () => {
    const { data } = await refetchTaxNumber();
    taxInvoiceSetValue(
      "tax_invoice_number",
      data?.utilGetTaxInvoiceNumber ?? "",
      { shouldValidate: true }
    );
  }, [refetchTaxNumber, taxInvoiceSetValue]);

  useEffect(() => {
    if (taxInvoiceNumberModal && (taxPrefix === "INV" || taxPrefix === "IVC")) {
      generateUniqueId();
    }
  }, [generateUniqueId, taxInvoiceNumberModal, taxPrefix]);

  const { mutate: setTaxInvoiceNumber } =
    useSalesOrderSetTaxInvoiceNumberMutation<Error>(
      graphQLClientWithHeaderSales,
      {
        onSuccess: () => {
          enqueueSnackbar(`บันทึกใบกำกับภาษีสำเร็จ`, {
            variant: "success",
          });
          onClose();
          if (id) {
            refetch();
          }
        },
        onError: (error: any) => {
          const errorMessages = error.response.errors.map(
            (err: any) => err.message
          );
          let snackbarMessage: string;
          if (
            errorMessages.includes(
              "This sales order's tax invoice ID has already been set"
            )
          ) {
            snackbarMessage = "ใบสั่งขายนี้มีใบกำกับภาษีแล้ว";
          } else if (
            errorMessages.includes("This sales order has been cancelled")
          ) {
            snackbarMessage = "ใบสั่งขายนี้ถูกยกเลิกไปแล้ว";
          } else if (
            errorMessages.includes("This tax invoice ID has already been used")
          ) {
            snackbarMessage = "เลขที่ใบกำกับภาษีซ้ำกันภายในระบบ";
          } else {
            snackbarMessage = "บันทึกใบกำกับภาษีไม่สำเร็จ";
          }
          enqueueSnackbar(snackbarMessage, {
            variant: "error",
          });
        },
      }
    );

  const taxInvoiceNumberHandler = (data: ITaxInvoice) => {
    setTaxInvoiceNumber({
      uniqueInput: {
        unique_id: id,
      },
      taxInvoiceDate: data.tax_invoice_date,
      taxInvoiceNumber: data.tax_invoice_prefix + data.tax_invoice_number,
    });
  };

  return (
    <ModalUI
      title="บันทึกใบกำกับภาษี"
      open={taxInvoiceNumberModal}
      handleClose={onClose}
      maxWidth="sm"
      fullWidth
      action={
        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "flex-end",
            gap: ".5rem",
          }}
        >
          <CustomizedButton
            title="ยกเลิก"
            variant="outlined"
            onClick={onClose}
          />
          <CustomizedButton
            title="ยืนยัน"
            variant="contained"
            onClick={taxInvoiceHandleSubmit(taxInvoiceNumberHandler)}
          />
        </Box>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ControlledDatePicker
            name="tax_invoice_date"
            control={taxInvoiceControl}
            label={t("sales.tax_invoice_date")}
            error={taxInvoiceErrors.tax_invoice_date}
            open={openDate}
            onOpen={() => {
              setOpenDate(true);
            }}
            onClose={() => {
              setOpenDate(false);
            }}
            required
          />
        </Grid>
        <Box width={"100%"} />
        <Grid item xs={6}>
          <Controller
            name="tax_invoice_prefix"
            control={taxInvoiceControl}
            defaultValue=""
            render={({ field }) => {
              return (
                <Autocomplete
                  freeSolo
                  forcePopupIcon
                  options={defaultPrefixOptions}
                  getOptionLabel={(option) => option.label || ""}
                  isOptionEqualToValue={(option, value) => {
                    if (!value) return false;
                    if (typeof value === "string") {
                      return option.value === value;
                    }
                    return option.value === value.value;
                  }}
                  onInputChange={(_, newInputValue) => {
                    field.onChange(newInputValue);
                  }}
                  onChange={(_, newValue) => {
                    if (newValue) {
                      if (newValue.value === "") {
                        field.onChange("");
                      } else {
                        field.onChange(newValue.value);
                      }
                    } else {
                      taxInvoiceSetValue("tax_invoice_number", "");
                    }
                  }}
                  value={
                    field.value
                      ? defaultPrefixOptions.find(
                          (opt) => opt.value === field.value
                        ) || { label: field.value, value: field.value }
                      : null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("sales.tax_invoice_prefix")}
                      size="small"
                      error={Boolean(taxInvoiceErrors.tax_invoice_prefix)}
                      helperText={
                        taxInvoiceErrors?.tax_invoice_prefix &&
                        taxInvoiceErrors?.tax_invoice_prefix.message
                      }
                      required
                    />
                  )}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Box display={"flex"}>
            <ControlledTextField
              label={t("sales.tax_invoice_number")}
              control={taxInvoiceControl}
              name="tax_invoice_number"
              error={Boolean(taxInvoiceErrors?.tax_invoice_number)}
              helperText={
                taxInvoiceErrors?.tax_invoice_number &&
                taxInvoiceErrors?.tax_invoice_number.message
              }
              required
            />
            <CustomizedTooltip
              title="เรียกเลขที่ใบกำกับภาษีใหม่"
              enterNextDelay={200}
            >
              <IconButton
                onClick={() => {
                  const prefix = taxInvoiceGetValues("tax_invoice_prefix");
                  if (prefix) {
                    generateUniqueId();
                  }
                }}
                sx={{
                  height: "fit-content",
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <RestartAltOutlinedIcon />
              </IconButton>
            </CustomizedTooltip>
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          mt: 2,
        }}
      >
        <Alert severity="info" style={{ backgroundColor: "#e5f6fd" }}>
          หากบันทึกแล้วจะไม่สามารถเปลี่ยนแปลงได้
        </Alert>
      </Box>
    </ModalUI>
  );
};

export default TaxInvoiceModal;

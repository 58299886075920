import {
  ColDef,
  GridReadyEvent,
  IServerSideGetRowsParams,
  RowDoubleClickedEvent,
} from "ag-grid-community";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AgGrid from "../../../UI/AgGrid";
import { useCallback, useEffect, useRef } from "react";
import { useQuotationColumnDefs } from "./columnDefs";
import { QUOTATIONS_VIEW_AGGRID } from "../../../../services/AgGrid/SalesAgGrid";
import { AgGridReact } from "ag-grid-react";
import { IQuotation } from "../../../../types/Sales/quotation";
import { dateFilterModel } from "../../../../utils/Formatter/AgGridFilter";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { useStateContext } from "../../../../contexts/auth-context";

interface Props {
  isFilter?: string | null;
}

const QuotationTable = ({ isFilter }: Props) => {
  const gridRef = useRef<AgGridReact<IQuotation>>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    state: { permissions, authUser },
  } = useStateContext();

  const columnDef: ColDef[] | undefined = useQuotationColumnDefs(t, false);

  const graphQLClientWithHeaderSales: GraphQLClient =
    createGraphQLClientWithMiddleware("sales");

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const {
        created_date,
        issue_date,
        due_date,
        related_user_object_list,
        created_by_object,
        ...otherFilterModel
      } = filterModel;

      const orConditions = [
        {
          created_by_unique_id: {
            filterType: "text",
            type: "equals",
            filter: authUser?.unique_id,
          },
          related_user_unique_id_list: {
            filterType: "array",
            type: "hasSome",
            values: [authUser?.unique_id],
          },
        },
      ];

      const formatFilter = {
        ...otherFilterModel,
        created_date: dateFilterModel(created_date),
        issue_date: dateFilterModel(issue_date),
        due_date: dateFilterModel(due_date),
        created_by: created_by_object,
        _or:
          permissions?.sales_order.view === "SELF"
            ? {
                _orArray: orConditions,
              }
            : undefined,
        related_user_list: related_user_object_list
          ? {
              filterType: "array",
              type: "hasSome",
              values: related_user_object_list.values,
            }
          : undefined,
      };
      try {
        const { quotationViewFindManyAggrid } =
          await graphQLClientWithHeaderSales.request(QUOTATIONS_VIEW_AGGRID, {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          });
        params.success({
          rowData: quotationViewFindManyAggrid.data as any[],
          rowCount: quotationViewFindManyAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onFilterChanged = useCallback(
    (params: any) => {
      const instance = params.api.getFilterInstance("aggrid_status");
      switch (isFilter) {
        case "draft":
          instance?.setModel({ values: ["draft"] });
          break;
        case "wait_approve":
          instance?.setModel({ values: ["wait_approve"] });
          break;
        case "not_approved":
          instance?.setModel({ values: ["not_approved"] });
          break;
        case "wait_accept":
          instance?.setModel({ values: ["wait_accept"] });
          break;
        case "accepted":
          instance?.setModel({ values: ["accepted"] });
          break;
        case "finished":
          instance?.setModel({ values: ["finished"] });
          break;
        case "cancelled":
          instance?.setModel({ values: ["cancelled"] });
          break;
        case "expired":
          instance?.setModel({ values: ["expired"] });
          break;
        default:
          instance?.setModel({});
          break;
      }
      params.api.onFilterChanged();
    },
    [isFilter]
  );

  const onGridReady = (params: GridReadyEvent) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
  }, [gridRef, onFilterChanged]);

  const onRowDoubleClicked = (params: RowDoubleClickedEvent) => {
    navigate(`/sales/quotation/${encodeURIComponent(params.data.unique_id)}`);
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDef}
      height={665}
      onGridReady={onGridReady}
      onRowDoubleClicked={onRowDoubleClicked}
      path={"/sales/quotation"}
    />
  );
};

export default QuotationTable;

import { useState, Fragment, useRef } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";

import { IBreadcrumbsAndMenu, ITab } from "../../../types/global";

import HeaderLayout from "../../../components/UI/HeaderLayout";
import ManufactureOrderTable, { ManufactureOrderTableType } from "../../../components/Table/Manufacture/Order";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import ButtonLayout from "../../../components/UI/ButtonLayout";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedMenuOptions from "../../../components/Custom/CustomizedMenuOptions";
import ConfigurableAgGridEditButton from "../../../components/UI/ConfigurableAgGrid/ConfigurableAgGridEditButton";

const ManufactureOrder = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const tableRef = useRef<ManufactureOrderTableType>()
  const isFilter = searchParams.get("filter");

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("manufacture.index"),
      to: "/manufacture",
    },
    {
      name: t("manufacture.order.index"),
    },
  ];

  const tabs: ITab[] = [
    {
      label: t("inventory.all"),
      path: `${pathname}`,
    },
    {
      label: t("status.draft"),
      path: `${pathname}?filter=draft`,
    },
    {
      label: t("status.pending_manu"),
      path: `${pathname}?filter=pending_manu`,
    },
    {
      label: t("status.in_progress"),
      path: `${pathname}?filter=in_progress`,
    },
    {
      label: t("status.finished"),
      path: `${pathname}?filter=finished`,
    },
    {
      label: t("status.cancelled"),
      path: `${pathname}?filter=cancelled`,
    },
  ];

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  const [view, setView] = useState<string>("table");

  const handleView = (_: any, newView: any) => {
    setView(newView);
  };

  return (
    <Fragment>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout>
        <Typography variant="h5">{t("manufacture.order.index")}</Typography>
        <ButtonLayout>
          <ToggleButtonGroup
            color="primary"
            value={view}
            size="small"
            exclusive
            onChange={handleView}
            aria-label="view"
          >
            <ToggleButton value="table" aria-label="table view">
              <FormatListBulletedIcon />
            </ToggleButton>
            <ToggleButton disabled value="calendar" aria-label="calendar view">
              <CalendarTodayIcon />
            </ToggleButton>
          </ToggleButtonGroup>
          <CustomizedButton
            title={`สร้าง${t("manufacture.order.index")}`}
            variant="contained"
            onClick={() => navigate(`/manufacture/order/add`)}
          />
          <ConfigurableAgGridEditButton tableRef={tableRef}/>
        </ButtonLayout>
      </HeaderLayout>
      <CustomizedTab table tabs={tabs} currentTab={currentTab} divider/>
      <ManufactureOrderTable isFilter={isFilter} ref={tableRef} />
    </Fragment>
  );
};

export default ManufactureOrder;

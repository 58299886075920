import { AgGridReact } from "ag-grid-react";
import { GraphQLClient } from "graphql-request";
import { useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { useContactViewColumnDefs } from "./viewColumnDefs";
import {
  ColDef,
  GridReadyEvent,
  IServerSideGetRowsParams,
  RowDoubleClickedEvent,
} from "ag-grid-community";
import { CONTACTS_VIEW } from "../../../services/AgGrid/ContactAgGrid";
import AgGrid from "../../UI/AgGrid";
import { dateFilterModel } from "../../../utils/Formatter/AgGridFilter";
import { Box } from "@mui/material";

type Props = {
  isFilter?: string | null;
};

const ContactViewTable = ({ isFilter }: Props) => {
  const gridRef = useRef<AgGridReact>(null);
  const navigate = useNavigate();

  const graphQLClientWithHeaderContact: GraphQLClient =
    createGraphQLClientWithMiddleware("contact");

  const columnDefs: ColDef[] = useContactViewColumnDefs();

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const {
        type,
        tag_list,
        is_active,
        contact_channel_list_type,
        sales_list_text,
        created_date,
        ...otherFilter
      } = filterModel;
      const formatFilter = {
        ...otherFilter,
        is_active: is_active && {
          ...is_active,
          values: is_active.values.map((v: string) => parseInt(v)),
        },
        tag_list: tag_list && {
          filterType: "array",
          type: "hasSome",
          values: tag_list.values,
        },
        type: type && {
          filterType: "array",
          type: "hasSome",
          values: type.values,
        },
        contact_channel_list_type: contact_channel_list_type && {
          filterType: "array",
          type: "hasSome",
          values: contact_channel_list_type.values,
        },
        sales_list_text: sales_list_text && {
          filterType: "array",
          type: "hasSome",
          values: sales_list_text.values,
        },
        created_date: dateFilterModel(created_date),
      };
      try {
        const { contactViews } = await graphQLClientWithHeaderContact.request(
          CONTACTS_VIEW,
          {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          }
        );
        params.success({
          rowData: contactViews.data as any[],
          rowCount: contactViews.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onFilterChanged = useCallback(
    (params: any) => {
      switch (isFilter) {
        case "customer":
          params.api.setFilterModel({
            type: {
              values: ["is_customer"],
            },
            is_active: {
              values: ["1"],
            },
          });
          break;
        case "vendor":
          params.api.setFilterModel({
            type: {
              values: ["is_vendor"],
            },
            is_active: {
              values: ["1"],
            },
          });
          break;
        default:
          params.api.setFilterModel({
            type: undefined,
            is_active: {
              values: ["1"],
            },
          });
          break;
      }
      params.api.onFilterChanged();
    },
    [isFilter]
  );

  const onGridReady = (params: GridReadyEvent) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
  }, [gridRef, onFilterChanged]);

  const onRowDoubleClicked = (params: RowDoubleClickedEvent) => {
    const { unique_id } = params.data;
    navigate(`/contact/all/${encodeURIComponent(unique_id)}`);
  };

  return (
    <Box
      sx={{
        "& .ag-floating-filter-body": {
          position: "relative",
          margin: 0,
          width: "100%",
        },
        "& .ag-header-cell .ag-floating-filter-button": {
          margin: 0,
          transition: "visibility 0s, opacity 0.3s ease",
          opacity: 0,
        },
        "& .ag-header-cell:hover .ag-floating-filter-button": {
          opacity: 1,
        },
      }}
    >
      <AgGrid
        ref={gridRef}
        columnDefs={columnDefs}
        height={665}
        onGridReady={onGridReady}
        onRowDoubleClicked={onRowDoubleClicked}
        path={"/contact/all"}
        paginationSize={50}
      />
    </Box>
  );
};

export default ContactViewTable;
